export const Provincias = [
  {
    value: "San José", // Provincia San Jose
    label: "San José",
    children: [
      {
        value: "Central",
        label: "Central", // Canton 1
        children: [
          {
            value: "Carmen",
            label: "Carmen", // Distrito 1
          },
          {
            value: "Merced",
            label: "Merced", // Distrito 2
          },
          {
            value: "Hospital",
            label: "Hospital", // Distrito 3
          },
          {
            value: "Catedral",
            label: "Catedral", // Distrito 4
          },
          {
            value: "Zapote",
            label: "Zapote", // Distrito 5
          },
          {
            value: "San Francisco De Dos Rios",
            label: "San Francisco De Dos Rios", // Distrito 6
          },
          {
            value: "Uruca",
            label: "Uruca", // Distrito 7
          },
          {
            value: "Mata Redonda",
            label: "Mata Redonda", // Distrito 8
          },
          {
            value: "Pavas",
            label: "Pavas", // Distrito 9
          },
          {
            value: "Hatillo",
            label: "Hatillo", // Distrito 10
          },
          {
            value: "San Sebastián",
            label: "San Sebastián", // Distrito 11
          },
        ],
      },
      {
        value: "Escazú",
        label: "Escazú", // canton 2
        children: [
          {
            value: "Escazú",
            label: "Escazú",
          },
          {
            value: "San Antonio",
            label: "San Antonio",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
        ],
      },
      {
        value: "Desamparados",
        label: "Desamparados",
        children: [
          {
            value: "Desamparados",
            label: "Desamparados", // Distrito 1
          },
          {
            value: "San Miguel",
            label: "San Miguel", // Distrito 2
          },
          {
            value: "San Juan De Dios",
            label: "San Juan De Dios", // Distrito 3
          },
          {
            value: "San Rafael Arriba",
            label: "San Rafael Arriba", // Distrito 4
          },
          {
            value: "San Rafael Abajo",
            label: "San Rafael Abajo", // Distrito 5
          },
          {
            value: "San Antonio",
            label: "San Antonio", // Distrito 6
          },
          {
            value: "Frailes",
            label: "Frailes", // Distrito 7
          },
          {
            value: "Patarra",
            label: "Patarra", // Distrito 8
          },
          {
            value: "San Cristobal",
            label: "San Cristobal", // Distrito 9
          },
          {
            value: "Rosario",
            label: "Rosario", // Distrito 10
          },
          {
            value: "Damas",
            label: "Damas", // Distrito 11
          },
          {
            value: "Gravilias",
            label: "Gravilias", // Distrito 12
          },
          {
            value: "Los Guido",
            label: "Los Guido", // Distrito 13
          },
        ],
      },
      {
        value: "Puriscal",
        label: "Puriscal", // canton 3
        children: [
          {
            value: "Santiago",
            label: "Santiago",
          },
          {
            value: "Mercedes Sur",
            label: "Mercedes Sur",
          },
          {
            value: "Barbacoas",
            label: "Barbacoas",
          },
          {
            value: "Grifo Alto",
            label: "Grifo Alto",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Candelarita",
            label: "Candelarita",
          },
          {
            value: "Desamparaditos",
            label: "Desamparaditos",
          },
          {
            value: "San Antonio",
            label: "San Antonio",
          },
          {
            value: "Chires",
            label: "Chires",
          },
        ],
      },
      {
        value: "Tarrazú",
        label: "Tarrazú", // Canton 4
        children: [
          {
            value: "San Marcos",
            label: "San Marcos",
          },
          {
            value: "San Lorenzo",
            label: "San Lorenzo",
          },
          {
            value: "San Carlos",
            label: "San Carlos",
          },
        ],
      },
      {
        value: "Aserrí",
        label: "Aserrí", // Canton 5
        children: [
          {
            value: "Aserrí",
            label: "Aserrí",
          },
          {
            value: "Tarbaca",
            label: "Tarbaca",
          },
          {
            value: "Vuelta De Jorco",
            label: "Vuelta De Jorco",
          },
          {
            value: "San Gabriel",
            label: "San Gabriel",
          },
          {
            value: "Legua",
            label: "Legua",
          },
          {
            value: "Monterrey",
            label: "Monterrey",
          },
          {
            value: "Salitrillos",
            label: "Salitrillos",
          },
        ],
      },
      {
        value: "Mora",
        label: "Mora", // Canton 6
        children: [
          {
            value: "Colón",
            label: "Colón",
          },
          {
            value: "Guayabo",
            label: "Guayabo",
          },
          {
            value: "Tabarcia",
            label: "Tabarcia",
          },
          {
            value: "Piedras Negras",
            label: "Piedras Negras",
          },
          {
            value: "Picagres",
            label: "Picagres",
          },
          {
            value: "Jaris",
            label: "Jaris",
          },
        ],
      },
      {
        value: "Goicoechea", //Canton 7
        label: "Goicoechea",
        children: [
          {
            value: "Guadalupe",
            label: "Guadalupe",
          },
          {
            value: "San Francisco",
            label: "San Francisco",
          },
          {
            value: "Calle Blancos",
            label: "Calle Blancos",
          },
          {
            value: "Mata De Platano",
            label: "Mata De Platano",
          },
          {
            value: "Ipís",
            label: "Ipís",
          },
          {
            value: "Rancho Redondo",
            label: "Rancho Redondo",
          },
          {
            value: "Purral",
            label: "Purral",
          },
        ],
      },
      {
        value: "Santa Ana",
        label: "Santa Ana", // Canton 8
        children: [
          {
            value: "Santa Ana",
            label: "Santa Ana",
          },
          {
            value: "Salitral",
            label: "Salitral",
          },
          {
            value: "Pozos",
            label: "Pozos",
          },
          {
            value: "Uruca",
            label: "Uruca",
          },
          {
            value: "Piedades",
            label: "SPiedades",
          },
          {
            value: "Brasil",
            label: "Brasil",
          },
        ],
      },
      {
        value: "Alajuelita", // Canton 9
        label: "Alajuelita",
        children: [
          {
            value: "Alajuelita",
            label: "Alajuelita",
          },
          {
            value: "San Josecito",
            label: "San Josecito",
          },
          {
            value: "San Antonio",
            label: "San Antonio",
          },
          {
            value: "Concepción",
            label: "Concepción",
          },
          {
            value: "San Felipe",
            label: "San Felipe",
          },
        ],
      },
      {
        value: "Vázquez De Coronado",
        label: "Vázquez De Coronado", // Canton 10
        children: [
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Dulce Nombre De Jesus",
            label: "Dulce Nombre De Jesus",
          },
          {
            value: "Patalillo",
            label: "Patalillo",
          },
          {
            value: "Cascajal",
            label: "Cascajal",
          },
        ],
      },
      {
        value: "Acosta",
        label: "Acosta", // Canton 11
        children: [
          {
            value: "San Ignacio",
            label: "San Ignacio",
          },
          {
            value: "Guaitil",
            label: "Guaitil",
          },
          {
            value: "Palmichal",
            label: "Palmichal",
          },
          {
            value: "Cangrejal",
            label: "Cangrejal",
          },
          {
            value: "Sabanillas",
            label: "Sabanillas",
          },
        ],
      },
      {
        value: "Tibás",
        label: "Tibás", // Canton 12
        children: [
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "Cinco Esquinas",
            label: "Cinco Esquinas",
          },
          {
            value: "Anselmo Llorente",
            label: "Anselmo Llorente",
          },
          {
            value: "Leon Xiii",
            label: "Leon Xiii",
          },
          {
            value: "Colima",
            label: "Colima",
          },
        ],
      },
      {
        value: "Moravia",
        label: "Moravia", // Canton 13
        children: [
          {
            value: "San Vicente",
            label: "San Vicente",
          },
          {
            value: "San Jeronimo",
            label: "San Jeronimo",
          },
          {
            value: "La Trinidad",
            label: "La Trinidad",
          },
        ],
      },
      {
        value: "Montes De Oca",
        label: "Montes De Oca", // canton 14
        children: [
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "Sabanilla",
            label: "Sabanilla",
          },
          {
            value: "Mercedes",
            label: "Mercedes",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
        ],
      },
      {
        value: "Turrubares",
        label: "Turrubares", // Canton 15
        children: [
          {
            value: "San Pablo",
            label: "San Pablo",
          },
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "San Juan De Mata",
            label: "San Juan De Mata",
          },
          {
            value: "San Luis",
            label: "San Luis",
          },
          {
            value: "Carara",
            label: "Carara",
          },
        ],
      },
      {
        value: "Dota",
        label: "Dota", // Canton 16
        children: [
          {
            value: "Santa María",
            label: "Santa María",
          },
          {
            value: "Jardin",
            label: "Jardin",
          },
          {
            value: "Copey",
            label: "Copey",
          },
        ],
      },
      {
        value: "Curridabat",
        label: "Curridabat", // Canton 17
        children: [
          {
            value: "Curridabat",
            label: "Curridabat",
          },
          {
            value: "Granadilla",
            label: "Granadilla",
          },
          {
            value: "Sanchez",
            label: "Sanchez",
          },
          {
            value: "Tirrases",
            label: "Tirrases",
          },
        ],
      },
      {
        value: "Pérez Zeledón",
        label: "Pérez Zeledón", // Canton 18
        children: [
          {
            value: "San Isidro De El General",
            label: "San Isidro De El General",
          },
          {
            value: "El General",
            label: "El General",
          },
          {
            value: "Daniel Flores",
            label: "Daniel Flores",
          },
          {
            value: "Rivas",
            label: "Rivas",
          },
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "Platanares",
            label: "Platanares",
          },
          {
            value: "Pejibaye",
            label: "Pejibaye",
          },
          {
            value: "Cajon",
            label: "Cajon",
          },
          {
            value: "Baru",
            label: "Baru",
          },
          {
            value: "Rio Nuevo",
            label: "Rio Nuevo",
          },
          {
            value: "Páramo",
            label: "Páramo",
          },
        ],
      },
      {
        value: "León Cortés Castro",
        label: "León Cortés Castro", // Canton 19
        children: [
          {
            value: "San Pablo",
            label: "San Pablo",
          },
          {
            value: "San Andres",
            label: "San Andres",
          },
          {
            value: "Llano Bonito",
            label: "Llano Bonito",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "Santa Cruz",
            label: "Santa Cruz",
          },
          {
            value: "San Antonio",
            label: "San Antonio",
          },
        ],
      },
    ],
  },

  {
    value: "Alajuela", // Provincia Alajuela
    label: "Alajuela",
    children: [
      {
        value: "Central", // Canton 1
        label: "Central",
        children: [
          {
            value: "Alajuela",
            label: "Alajuela",
          },
          {
            value: "San José",
            label: "San José",
          },
          {
            value: "Carrizal",
            label: "Carrizal",
          },
          {
            value: "San Antonio",
            label: "San Antonio",
          },
          {
            value: "Guácima",
            label: "Guácima",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "Sabanilla",
            label: "Sabanilla",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Rio Segundo",
            label: "Rio Segundo",
          },
          {
            value: "Desamparados",
            label: "Desamparados",
          },
          {
            value: "Turrucares",
            label: "Turrucares",
          },
          {
            value: "Tambor",
            label: "Tambor",
          },
          {
            value: "Garita",
            label: "Garita",
          },
          {
            value: "Sarapiquí",
            label: "Sarapiquí",
          },
        ],
      },
      {
        value: "San Ramón",
        label: "San Ramón", // Canton 2
        children: [
          {
            value: "San Ramón",
            label: "San Ramón",
          },
          {
            value: "Santiago",
            label: "Santiago",
          },
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "Piedades Norte",
            label: "Piedades Norte",
          },
          {
            value: "Piedades Sur",
            label: "Piedades Sur",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "Angeles",
            label: "Angeles",
          },
          {
            value: "Alfaro",
            label: "Alfaro",
          },
          {
            value: "Volio",
            label: "Volio",
          },
          {
            value: "Concepción",
            label: "Concepción",
          },
          {
            value: "Zapotal",
            label: "Zapotal",
          },
          {
            value: "Peñas Blancas",
            label: "Peñas Blancas",
          },
        ],
      },
      {
        value: "Grecia",
        label: "Grecia", // Canton 3
        children: [
          {
            value: "Grecia",
            label: "Grecia",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "San José",
            label: "San José",
          },
          {
            value: "San Roque",
            label: "San Roque",
          },
          {
            value: "Tacares",
            label: "Tacares",
          },
          {
            value: "Rio Cuarto",
            label: "Rio Cuarto",
          },
          {
            value: "Puente De Piedra",
            label: "Puente De Piedra",
          },
          {
            value: "Bolivar",
            label: "Bolivar",
          },
        ],
      },
      {
        value: "San Mateo",
        label: "San Mateo", // Canton 4
        children: [
          {
            value: "San Mateo",
            label: "San Mateo",
          },
          {
            value: "Desmonte",
            label: "Desmonte",
          },
          {
            value: "Jesús María",
            label: "Jesús María",
          },
          {
            value: "Labrador",
            label: "Labrador",
          },
        ],
      },
      {
        value: "Atenas", // Canton 5
        label: "Atenas",
        children: [
          {
            value: "Atenas",
            label: "Atenas",
          },
          {
            value: "Jesús",
            label: "Jesús",
          },
          {
            value: "Mercedes",
            label: "Mercedes",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "Concepción",
            label: "Concepción",
          },
          {
            value: "San José",
            label: "San José",
          },
          {
            value: "Santa Eulalia",
            label: "Santa Eulalia",
          },
          {
            value: "Escobal",
            label: "Escobal",
          },
        ],
      },
      {
        value: "Naranjo", // Canton 6
        label: "Naranjo",
        children: [
          {
            value: "Naranjo",
            label: "Naranjo",
          },
          {
            value: "San Miguel",
            label: "San Miguel",
          },
          {
            value: "San José",
            label: "San José",
          },
          {
            value: "Cirrí Sur",
            label: "Cirrí Sur",
          },
          {
            value: "San Jerónimo",
            label: "San Jerónimo",
          },
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "El Rosari",
            label: "El Rosari",
          },
          {
            value: "Palmitos",
            label: "Palmitos",
          },
        ],
      },
      {
        value: "Palmares", // Canton 7
        label: "Palmares",
        children: [
          {
            value: "Palmares",
            label: "Palmares",
          },
          {
            value: "Zaragoza",
            label: "Zaragoza",
          },
          {
            value: "Buenos Aires",
            label: "Buenos Aires",
          },
          {
            value: "Santiago",
            label: "Santiago",
          },
          {
            value: "Candelaria",
            label: "Candelaria",
          },
          {
            value: "Esquipulas",
            label: "Esquipulas",
          },
          {
            value: "La Granja",
            label: "La Granja",
          },
        ],
      },
      {
        value: "Poás", // Canton 8
        label: "Poás",
        children: [
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Carrillos",
            label: "Carrillos",
          },
          {
            value: "Sabana Redonda",
            label: "Sabana Redonda",
          },
        ],
      },
      {
        value: "Orotina", // Canton 9
        label: "Orotina",
        children: [
          {
            value: "Orotina",
            label: "Orotina",
          },
          {
            value: "El Mastate",
            label: "El Mastate",
          },
          {
            value: "Hacienda Vieja",
            label: "Hacienda Vieja",
          },
          {
            value: "Coyolar",
            label: "Coyolar",
          },
          {
            value: "La Ceiba",
            label: "La Ceiba",
          },
        ],
      },
      {
        value: "San Carlos", // Canton 10
        label: "San Carlos",
        children: [
          {
            value: "Quesada",
            label: "Quesada",
          },
          {
            value: "Florencia",
            label: "Florencia",
          },
          {
            value: "Buenavista",
            label: "Buenavista",
          },
          {
            value: "Aguas Zarcas",
            label: "Aguas Zarcas",
          },
          {
            value: "Venecia",
            label: "Venecia",
          },
          {
            value: "Pital",
            label: "Pital",
          },
          {
            value: "La Fortuna",
            label: "La Fortuna",
          },
          {
            value: "La Tigra",
            label: "La Tigra",
          },
          {
            value: "La Palmera",
            label: "La Palmera",
          },
          {
            value: "Venado",
            label: "Venado",
          },
          {
            value: "Cutris",
            label: "Cutris",
          },
          {
            value: "Monterrey",
            label: "Monterrey",
          },
          {
            value: "Pocosol",
            label: "Pocosol",
          },
        ],
      },
      {
        value: "Zarcero", // Canton 11
        label: "Zarcero",
        children: [
          {
            value: "Zarcero",
            label: "Zarcero",
          },
          {
            value: "Laguna",
            label: "Laguna",
          },
          {
            value: "Tapesco",
            label: "Tapesco",
          },
          {
            value: "Guadalupe",
            label: "Guadalupe",
          },
          {
            value: "Palmira",
            label: "Palmira",
          },
          {
            value: "Zapote",
            label: "Zapote",
          },
          {
            value: "Brisas",
            label: "Brisas",
          },
        ],
      },
      {
        value: "Valverde Vega", // Canton 12
        label: "Valverde Vega",
        children: [
          {
            value: "Sarchí Norte",
            label: "Sarchí Norte",
          },
          {
            value: "Sarchí Sur",
            label: "Sarchí Sur",
          },
          {
            value: "Toro Amarillo",
            label: "Toro Amarillo",
          },
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "Rodriguez",
            label: "Rodriguez",
          },
        ],
      },
      {
        value: "Upala", // Canton 13
        label: "Upala",
        children: [
          {
            value: "Upala",
            label: "Upala",
          },
          {
            value: "Aguas Claras",
            label: "Aguas Claras",
          },
          {
            value: "San José o Pizote",
            label: "San José o Pizote",
          },
          {
            value: "Bijagua",
            label: "Bijagua",
          },
          {
            value: "Delicias",
            label: "Delicias",
          },
          {
            value: "Dos Rios",
            label: "Dos Rios",
          },
          {
            value: "Yolillal",
            label: "Yolillal",
          },
          {
            value: "Canalete",
            label: "Canalete",
          },
        ],
      },
      {
        value: "Los Chiles", // Canton 14
        label: "Los Chiles",
        children: [
          {
            value: "Los Chiles",
            label: "Los Chiles",
          },
          {
            value: "Caño Negro",
            label: "Caño Negro",
          },
          {
            value: "El Amparo",
            label: "El Amparo",
          },
          {
            value: "San Jorge",
            label: "San Jorge",
          },
        ],
      },
      {
        value: "Guatuso",
        label: "Guatuso", // Canton 15
        children: [
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Buenavista",
            label: "Buenavista",
          },
          {
            value: "Cote",
            label: "Cote",
          },
          {
            value: "Katira",
            label: "Katira",
          },
        ],
      },
      {
        value: "Río Cuarto", // Canton 16
        label: "Río Cuarto",
        children: [
          {
            value: "Río Cuarto",
            label: "Río Cuarto",
          },
        ],
      },
    ],
  },
  {
    value: "Cartago", // Provincia Cartago
    label: "Cartago",
    children: [
      {
        value: "Central", // Canton 1
        label: "Central",
        children: [
          {
            value: "Oriental",
            label: "Oriental",
          },
          {
            value: "Occidental",
            label: "Occidental",
          },
          {
            value: "Carmen",
            label: "Carmen",
          },
          {
            value: "San Nicolás",
            label: "San Nicolás",
          },
          {
            value: "Aguacaliente o San Francisco",
            label: "Aguacaliente o San Francisco",
          },
          {
            value: "Guadalupe o Arenilla",
            label: "Guadalupe o Arenilla",
          },
          {
            value: "Corralillo",
            label: "Corralillo",
          },
          {
            value: "Tierra Blanca",
            label: "Tierra Blanca",
          },
          {
            value: "Dulce Nombr",
            label: "Dulce Nombr",
          },
          {
            value: "Llano Grande",
            label: "Llano Grande",
          },
          {
            value: "Quebradilla",
            label: "Quebradilla",
          },
        ],
      },
      {
        value: "Paraiso", // Canton 2
        label: "Paraiso",
        children: [
          {
            value: "Paraiso",
            label: "Paraiso",
          },
          {
            value: "Santiago",
            label: "Santiago",
          },
          {
            value: "Orosi",
            label: "Orosi",
          },
          {
            value: "Cachí",
            label: "Cachí",
          },
          {
            value: "Llanos de Santa Lucía",
            label: "Llanos de Santa Lucía",
          },
        ],
      },
      {
        value: "La Union", // Canton 3
        label: "La Union",
        children: [
          {
            value: "Tres Rios",
            label: "Tres Rios",
          },
          {
            value: "San Diego",
            label: "San Diego",
          },
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Concepción",
            label: "Concepción",
          },
          {
            value: "Dulce Nombre",
            label: "Dulce Nombre",
          },
          {
            value: "San Ramón",
            label: "San Ramón",
          },
          {
            value: "Rio Azul",
            label: "Rio Azul",
          },
        ],
      },
      {
        value: "Jimenez", // Canton 4
        label: "Jiménez",
        children: [
          {
            value: "Juan Viñas",
            label: "Juan Viñas",
          },
          {
            value: "Tucurrique",
            label: "Tucurrique",
          },
          {
            value: "Pejibaye",
            label: "Pejibaye",
          },
        ],
      },
      {
        value: "Turrialba", // Canton 5
        label: "Turrialba",
        children: [
          {
            value: "Turrialba",
            label: "Turrialba",
          },
          {
            value: "La Suiza",
            label: "La Suiza",
          },
          {
            value: "Peralta",
            label: "Peralta",
          },
          {
            value: "Santa Cruz",
            label: "Santa Cruz",
          },
          {
            value: "Santa Teresita",
            label: "Santa Teresita",
          },
          {
            value: "Pavones",
            label: "Pavones",
          },
          {
            value: "Tuis",
            label: "Tuis",
          },
          {
            value: "Tayutic",
            label: "Tayutic",
          },
          {
            value: "Santa Rosa",
            label: "Santa Rosa",
          },
          {
            value: "Tres Equis",
            label: "Tres Equis",
          },
          {
            value: "La Isabel",
            label: "La Isabel",
          },
          {
            value: "Chirripó",
            label: "Chirripó",
          },
        ],
      },
      {
        value: "Alvarado", // Canton 6
        label: "Alvarado",
        children: [
          {
            value: "Pacayas",
            label: "Pacayas",
          },
          {
            value: "Cervantes",
            label: "Cervantes",
          },
          {
            value: "Capellades",
            label: "Capellades",
          },
        ],
      },
      {
        value: "Oreamuno", // Canton 7
        label: "Oreamuno",
        children: [
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "Cot",
            label: "Cot",
          },
          {
            value: "Potrero Cerrado",
            label: "Potrero Cerrado",
          },
          {
            value: "Cipreses",
            label: "Cipreses",
          },
          {
            value: "Santa Rosa",
            label: "Santa Rosa",
          },
        ],
      },
      {
        value: "El Guarco", // Canton 8
        label: "El Guarco",
        children: [
          {
            value: "El Tejar",
            label: "El Tejar",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "Tobosi",
            label: "Tobosi",
          },
          {
            value: "Patio De Agua",
            label: "Patio De Agua",
          },
        ],
      },
    ],
  },
  {
    value: "Heredia", // Provincia Heredia
    label: "Heredia",
    children: [
      {
        value: "Central", // Canton 1
        label: "Central",
        children: [
          {
            value: "Heredia",
            label: "Heredia",
          },
          {
            value: "Mercedes",
            label: "Mercedes",
          },
          {
            value: "San Francisco",
            label: "San Francisco",
          },
          {
            value: "Ulloa",
            label: "Ulloa",
          },
          {
            value: "Varablanca",
            label: "Varablanca",
          },
        ],
      },
      {
        value: "Barva", // Canton 2
        label: "Barva",
        children: [
          {
            value: "Barva",
            label: "Barva",
          },
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "San Pablo",
            label: "San Pablo",
          },
          {
            value: "San Roque",
            label: "San Roque",
          },
          {
            value: "Santa Lucía",
            label: "Santa Lucía",
          },
          {
            value: "San José de la Montaña",
            label: "San José de la Montaña",
          },
        ],
      },
      {
        value: "Santo Domingo", // Canton 3
        label: "Santo Domingo",
        children: [
          {
            value: "Santo Domingo",
            label: "Santo Domingo",
          },
          {
            value: "Santo Vicente",
            label: "Santo Vicente",
          },
          {
            value: "Santo Miguel",
            label: "Santo Miguel",
          },
          {
            value: "Paracito",
            label: "Paracito",
          },
          {
            value: "Santo Tomás",
            label: "Santo Tomás",
          },
          {
            value: "Santa Rosa",
            label: "Santa Rosa",
          },
          {
            value: "Tures",
            label: "Tures",
          },
          {
            value: "Para",
            label: "Para",
          },
        ],
      },
      {
        value: "Santa Barbara", // Canton 4
        label: "Santa Barbara",
        children: [
          {
            value: "Santa Bárbara",
            label: "Santa Bárbara",
          },
          {
            value: "San Pedro",
            label: "San Pedro",
          },
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "Jesús",
            label: "Jesús",
          },
          {
            value: "Santo Domingo",
            label: "Santo Domingo",
          },
          {
            value: "Puraba",
            label: "Puraba",
          },
        ],
      },
      {
        value: "San Rafael", // Canton 5
        label: "San Rafael",
        children: [
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "San Josecito",
            label: "San Josecito",
          },
          {
            value: "Santiago",
            label: "Santiago",
          },
          {
            value: "Los Ángeles",
            label: "Los Ángeles",
          },
          {
            value: "Concepción",
            label: "Concepción",
          },
        ],
      },
      {
        value: "San Isidro", // Canton 6
        label: "San Isidro",
        children: [
          {
            value: "San Isidro",
            label: "San Isidro",
          },
          {
            value: "San José",
            label: "San José",
          },
          {
            value: "Concepción",
            label: "Concepción",
          },
          {
            value: "San Francisco",
            label: "San Francisco",
          },
        ],
      },
      {
        value: "Belén", // Canton 7
        label: "Belén",
        children: [
          {
            value: "San Antonio",
            label: "San Antonio",
          },
          {
            value: "La Ribera",
            label: "La Ribera",
          },
          {
            value: "La Asuncion",
            label: "La Asuncion",
          },
        ],
      },
      {
        value: "Flores", // Canton 8
        label: "Flores",
        children: [
          {
            value: "San Joaquín",
            label: "San Joaquín",
          },
          {
            value: "Barrantes",
            label: "Barrantes",
          },
          {
            value: "Llorente",
            label: "Llorente",
          },
        ],
      },
      {
        value: "San Pablo", // Canton 9
        label: "San Pablo",
        children: [
          {
            value: "San Pablo",
            label: "San Pablo",
          },
          {
            value: "Rincon De Sabanilla",
            label: "Rincon De Sabanilla",
          },
        ],
      },
      {
        value: "Sarapiquí", // Canton  10
        label: "Sarapiquí",
        children: [
          {
            value: "Puerto Viejo",
            label: "Puerto Viejo",
          },
          {
            value: "La Virgen",
            label: "La Virgen",
          },
          {
            value: "Las Horquetas",
            label: "Las Horquetas",
          },
          {
            value: "Llanuras Del Gaspar",
            label: "Llanuras Del Gaspar",
          },
          {
            value: "Cureña",
            label: "Cureña",
          },
        ],
      },
    ],
  },
  {
    value: "Guanacaste", // Provincia Guanacaste
    label: "Guanacaste",
    children: [
      {
        value: "Liberia", // Canton 1
        label: "Liberia",
        children: [
          {
            value: "Liberia",
            label: "Liberia",
          },
          {
            value: "Cañas Dulces",
            label: "Cañas Dulces",
          },
          {
            value: "Mayorga",
            label: "Mayorga",
          },
          {
            value: "Nacascolo",
            label: "Nacascolo",
          },
          {
            value: "Curubande",
            label: "Curubande",
          },
        ],
      },
      {
        value: "Nicoya", // Canton 2
        label: "Nicoya",
        children: [
          {
            value: "Nicoya",
            label: "Nicoya",
          },
          {
            value: "Mansión",
            label: "Mansión",
          },
          {
            value: "San Antonio",
            label: "San Antonio",
          },
          {
            value: "Quebrada Honda",
            label: "Quebrada Honda",
          },
          {
            value: "Sámara",
            label: "Sámara",
          },
          {
            value: "Nosara",
            label: "Nosara",
          },
          {
            value: "Belén De Nosarita",
            label: "Belén De Nosarita",
          },
        ],
      },
      {
        value: "Santa Cruz", // Canton 3
        label: "Santa Cruz",
        children: [
          {
            value: "Santa Cruz",
            label: "Santa Cruz",
          },
          {
            value: "Bolson",
            label: "Bolson",
          },
          {
            value: "Veintisiete de Abril",
            label: "Veintisiete de Abril",
          },
          {
            value: "Tempate",
            label: "Tempate",
          },
          {
            value: "Cartagena",
            label: "Cartagena",
          },
          {
            value: "Cuajiniquil",
            label: "Cuajiniquil",
          },
          {
            value: "Diria",
            label: "Diria",
          },
          {
            value: "Cabo Velas",
            label: "Cabo Velas",
          },
          {
            value: "Tamarindo",
            label: "Tamarindo",
          },
        ],
      },
      {
        value: "Bagaces", // Canton 4
        label: "Bagaces",
        children: [
          {
            value: "Bagaces",
            label: "Bagaces",
          },
          {
            value: "La Fortuna",
            label: "La Fortuna",
          },
          {
            value: "Mogote",
            label: "Mogote",
          },
          {
            value: "Rio Naranjo",
            label: "Rio Naranjo",
          },
        ],
      },
      {
        value: "Carrillo", // Canton 5
        label: "Carrillo",
        children: [
          {
            value: "Filadelfia",
            label: "Filadelfia",
          },
          {
            value: "Palmira",
            label: "Palmira",
          },
          {
            value: "Sardinal",
            label: "Sardinal",
          },
          {
            value: "Belen",
            label: "Belen",
          },
        ],
      },
      {
        value: "Cañas", // Canton 6
        label: "Cañas",
        children: [
          {
            value: "Cañas",
            label: "Cañas",
          },
          {
            value: "Palmira",
            label: "Palmira",
          },
          {
            value: "San Miguel",
            label: "San Miguel",
          },
          {
            value: "Bebedero",
            label: "Bebedero",
          },
          {
            value: "Porozal",
            label: "Porozal",
          },
        ],
      },
      {
        value: "Abangares", // Canton 7
        label: "Abangares",
        children: [
          {
            value: "Las Juntas",
            label: "Las Juntas",
          },
          {
            value: "Sierra",
            label: "Sierra",
          },
          {
            value: "San Juan",
            label: "San Juan",
          },
          {
            value: "Colorado",
            label: "Colorado",
          },
        ],
      },
      {
        value: "Tilarán", // Canton 8
        label: "Tilarán",
        children: [
          {
            value: "Tilarán",
            label: "Tilarán",
          },
          {
            value: "Quebrada Grande",
            label: "Quebrada Grande",
          },
          {
            value: "Tronadora",
            label: "Tronadora",
          },
          {
            value: "Santa Rosa",
            label: "Santa Rosa",
          },
          {
            value: "Líbano",
            label: "Líbano",
          },
          {
            value: "Tierras Morenas",
            label: "Tierras Morenas",
          },
          {
            value: "Arenal",
            label: "Arenal",
          },
        ],
      },
      {
        value: "Nandayure", // Canton 9
        label: "Nandayure",
        children: [
          {
            value: "Carmona",
            label: "Carmona",
          },
          {
            value: "Santa Rita",
            label: "Santa Rita",
          },
          {
            value: "Zapotal",
            label: "Zapotal",
          },
          {
            value: "San Pablo",
            label: "San Pablo",
          },
          {
            value: "Porvenir",
            label: "Porvenir",
          },
          {
            value: "Bejuco",
            label: "Bejuco",
          },
        ],
      },
      {
        value: "La Cruz", // Canton 10
        label: "La Cruz",
        children: [
          {
            value: "La Cruz",
            label: "La Cruz",
          },
          {
            value: "Santa Cecilia",
            label: "Santa Cecilia",
          },
          {
            value: "La Garita",
            label: "La Garita",
          },
          {
            value: "Santa Elena",
            label: "Santa Elena",
          },
        ],
      },
      {
        value: "Hojancha", // Canton 11
        label: "Hojancha",
        children: [
          {
            value: "Hojancha",
            label: "Hojancha",
          },
          {
            value: "Monte Romo",
            label: "Monte Romo",
          },
          {
            value: "Puerto Carrillo",
            label: "Puerto Carrillo",
          },
          {
            value: "Huacas",
            label: "Huacas",
          },
        ],
      },
    ],
  },
  {
    value: "Puntarenas", // Provincia  Puntarenas
    label: "Puntarenas",
    children: [
      {
        value: "Central", // Canton 1
        label: "Central",
        children: [
          {
            value: "Puntarenas",
            label: "Puntarenas",
          },
          {
            value: "Pitahaya",
            label: "Pitahaya",
          },
          {
            value: "Chomes",
            label: "Chomes",
          },
          {
            value: "Lepanto",
            label: "Lepanto",
          },
          {
            value: "Paquera",
            label: "Paquera",
          },
          {
            value: "Manzanillo",
            label: "Manzanillo",
          },
          {
            value: "Guacimal",
            label: "Guacimal",
          },
          {
            value: "Barranca",
            label: "Barranca",
          },
          {
            value: "Monte Verde",
            label: "Monte Verde",
          },
          {
            value: "Isla Del Coco",
            label: "Isla Del Coco",
          },
          {
            value: "Cóbano",
            label: "Cóbano",
          },
          {
            value: "Chacarita",
            label: "Chacarita",
          },
          {
            value: "Chira",
            label: "Chira",
          },
          {
            value: "Acapulco",
            label: "Acapulco",
          },
          {
            value: "El Roble",
            label: "El Roble",
          },
          {
            value: "Arancibia",
            label: "Arancibia",
          },
        ],
      },
      {
        value: "Esparza", // Canton 2
        label: "Esparza",
        children: [
          {
            value: "Espíritu Sant",
            label: "Espíritu Sant",
          },
          {
            value: "San Juan Grande",
            label: "San Juan Grande",
          },
          {
            value: "Macacona",
            label: "Macacona",
          },
          {
            value: "San Rafael",
            label: "San Rafael",
          },
          {
            value: "San Jerónimo",
            label: "San Jerónimo",
          },
        ],
      },
      {
        value: "Buenos Aires", // Canton 3
        label: "Buenos Aires",
        children: [
          {
            value: "Buenos Aires",
            label: "Buenos Aires",
          },
          {
            value: "Volcán",
            label: "Volcán",
          },
          {
            value: "Potrero Grande",
            label: "Potrero Grande",
          },
          {
            value: "Boruca",
            label: "Boruca",
          },
          {
            value: "Pilas",
            label: "Pilas",
          },
          {
            value: "Colinas",
            label: "Colinas",
          },
          {
            value: "Changuena",
            label: "Changuena",
          },
          {
            value: "Biolley",
            label: "Biolley",
          },
          {
            value: "Brunka",
            label: "Brunka",
          },
        ],
      },
      {
        value: "Montes De Oro", // Canton 4
        label: "Montes De Oro",
        children: [
          {
            value: "Miramar",
            label: "Miramar",
          },
          {
            value: "La Unión",
            label: "La Unión",
          },
          {
            value: "San Isidro",
            label: "San Isidro",
          },
        ],
      },
      {
        value: "Osa", // Canton 5
        label: "Osa",
        children: [
          {
            value: "Puerto Cortés",
            label: "Puerto Cortés",
          },
          {
            value: "Palmar",
            label: "Palmar",
          },
          {
            value: "Sierpe",
            label: "Sierpe",
          },
          {
            value: "Bahía Ballena",
            label: "Bahía Ballena",
          },
          {
            value: "Piedras Blancas",
            label: "Piedras Blancas",
          },
          {
            value: "Bahía Drake",
            label: "Bahía Drake",
          },
        ],
      },
      {
        value: "Quepos", // Canton 6
        label: "Quepos",
        children: [
          {
            value: "Quepos",
            label: "Quepos",
          },
          {
            value: "Savegre",
            label: "Savegre",
          },
          {
            value: "Naranjito",
            label: "Naranjito",
          },
        ],
      },
      {
        value: "Golfito", // Canton 7
        label: "Golfito",
        children: [
          {
            value: "Golfito",
            label: "Golfito",
          },
          {
            value: "Puerto Jiménez",
            label: "Puerto Jiménez",
          },
          {
            value: "Guaycara",
            label: "Guaycara",
          },
          {
            value: "Pavón",
            label: "Pavón",
          },
        ],
      },
      {
        value: "Coto Brus", // Canton 8
        label: "Coto Brus",
        children: [
          {
            value: "San Vito",
            label: "San Vito",
          },
          {
            value: "Sabalito",
            label: "Sabalito",
          },
          {
            value: "Aguabuena",
            label: "Aguabuena",
          },
          {
            value: "Limoncito",
            label: "Limoncito",
          },
          {
            value: "Pittier",
            label: "Pittier",
          },
        ],
      },
      {
        value: "Parrita", // Canton 9
        label: "Parrita",
        children: [
          {
            value: "Parrita",
            label: "Parrita",
          },
        ],
      },
      {
        value: "Corredores", // Canton 10
        label: "Corredores",
        children: [
          {
            value: "Corredor",
            label: "Corredor",
          },
          {
            value: "La Cuesta",
            label: "La Cuesta",
          },
          {
            value: "Canoas",
            label: "Canoas",
          },
          {
            value: "Laurel",
            label: "Laurel",
          },
        ],
      },
      {
        value: "Garabito", // Canton 11
        label: "Garabito",
        children: [
          {
            value: "Jacó",
            label: "Jacó",
          },
          {
            value: "Tárcoles",
            label: "Tárcoles",
          },
        ],
      },
    ],
  },
  {
    value: "Limón", // Provincia  Limón
    label: "Limón",
    children: [
      {
        value: "Central", // Canton 1
        label: "Central",
        children: [
          {
            value: "Limón",
            label: "Limón",
          },
          {
            value: "Valle La Estrella",
            label: "Valle La Estrella",
          },
          {
            value: "Rio Blanco",
            label: "Rio Blanco",
          },
          {
            value: "Matama",
            label: "Matama",
          },
        ],
      },
      {
        value: "Pococí", // Canton 2
        label: "Pococí",
        children: [
          {
            value: "Guapiles",
            label: "Guapiles",
          },
          {
            value: "Jiménez",
            label: "Jiménez",
          },
          {
            value: "Rita",
            label: "Rita",
          },
          {
            value: "Roxana",
            label: "Roxana",
          },
          {
            value: "Cariari",
            label: "Cariari",
          },
          {
            value: "Colorado",
            label: "Colorado",
          },
          {
            value: "La Colonia",
            label: "La Colonia",
          },
        ],
      },
      {
        value: "Siquirres", // Canton 3
        label: "Siquirres",
        children: [
          {
            value: "Siquirres",
            label: "Siquirres",
          },
          {
            value: "Pacuarito",
            label: "Pacuarito",
          },
          {
            value: "Florida",
            label: "Florida",
          },
          {
            value: "Germania",
            label: "Germania",
          },
          {
            value: "El Cairo",
            label: "El Cairo",
          },
          {
            value: "Alegría",
            label: "Alegría",
          },
        ],
      },
      {
        value: "Talamanca", // Canton 4
        label: "Talamanca",
        children: [
          {
            value: "Bratsi",
            label: "Bratsi",
          },
          {
            value: "Sixaola",
            label: "Sixaola",
          },
          {
            value: "Cahuita",
            label: "Cahuita",
          },
          {
            value: "Telire",
            label: "Telire",
          },
        ],
      },
      {
        value: "Matina", // Canton 5
        label: "Matina",
        children: [
          {
            value: "Matina",
            label: "Matina",
          },
          {
            value: "Batán",
            label: "Batán",
          },
          {
            value: "Carrandi",
            label: "Carrandi",
          },
        ],
      },
      {
        value: "Guácimo", // Canton 6
        label: "Guácimo",
        children: [
          {
            value: "Guácimo",
            label: "Guácimo",
          },
          {
            value: "Mercedes",
            label: "Mercedes",
          },
          {
            value: "Pocora",
            label: "Pocora",
          },
          {
            value: "Rio Jiménez",
            label: "Rio Jiménez",
          },
          {
            value: "Duacari",
            label: "Duacari",
          },
        ],
      },
    ],
  },
];
