import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Avatar,
  Skeleton,
  Space,
  Divider,
  Tooltip,
  Badge,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { GetMethod } from "../misc/CRUD";
import { Permissions } from "../misc/Generic";
import {
  TeamOutlined,
  PoweroffOutlined,
  ShopOutlined,
  UserOutlined,
  ClockCircleOutlined,
  BarcodeOutlined,
  AppstoreAddOutlined,
  PieChartOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { useGlobal } from "../context/GlobalContext";
import { Link, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
const { Sider } = Layout;
const { Item } = Menu;

export default function Sidebar() {
  const { API, logout, currentUser } = useGlobal();
  const [userProfile, setUserProfile] = useState();

  const fontSize = 18;
  const color = "#ccc";
  const history = useHistory();

  const exit = () => {
    logout();
    localStorage.clear();
    history.push("/");
  };

  useEffect(() => {
    let isMounted = true;

    GetMethod(`${API}users/${currentUser.email}`).then((userProfile) => {
      if (isMounted) {
        setUserProfile(userProfile);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [API, currentUser.email]);

  return (
    <Sider collapsible defaultCollapsed>
      {userProfile ? (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0,
            type: "spring",
            stiffness: 120,
          }}
        >
          <div align="center" style={{ paddingTop: "4vh" }}>
            <Space direction="vertical" size="middle">
              <Badge
                color={"lime"}
                offset={[-10, 10]}
                style={{
                  width: 10,
                  height: 10,
                }}
              >
                <Tooltip title={currentUser.email} placement="right">
                  {currentUser.photoURL ? (
                    <Avatar size={65} src={currentUser.photoURL} />
                  ) : (
                    <Avatar size={65}>
                      <span style={{ fontSize: 25 }}>
                        {`${userProfile.firstName.substring(
                          0,
                          1
                        )}${userProfile.lastName.substring(0, 1)}`}
                      </span>
                    </Avatar>
                  )}
                </Tooltip>
              </Badge>
            </Space>
            <Divider />
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[window.location.pathname]}
            mode="inline"
          >
            {Permissions("orders").map(
              (item) =>
                item === userProfile.role && (
                  <Item
                    key={"/orders"}
                    icon={
                      <AppstoreAddOutlined
                        style={{ fontSize: fontSize, color: color }}
                      />
                    }
                  >
                    <Link to="/orders" /> Nueva Orden
                  </Item>
                )
            )}

            {Permissions("history").map(
              (item) =>
                item === userProfile.role && (
                  <Item
                    key={"/history"}
                    icon={
                      <ClockCircleOutlined
                        style={{ fontSize: fontSize, color: color }}
                      />
                    }
                  >
                    <Link to="/history" /> Histórico
                  </Item>
                )
            )}

            {Permissions("scan").map(
              (item) =>
                item === userProfile.role && (
                  <Item
                    key={"/scan"}
                    icon={
                      <BarcodeOutlined
                        style={{ fontSize: fontSize, color: color }}
                      />
                    }
                  >
                    <Link to="/scan" /> Scan
                  </Item>
                )
            )}

            {Permissions("profile").map(
              (item) =>
                item === userProfile.role && (
                  <Item
                    key={"/profile"}
                    icon={
                      <UserOutlined
                        style={{ fontSize: fontSize, color: color }}
                      />
                    }
                  >
                    <Link to="/profile" />
                    Perfil
                  </Item>
                )
            )}

            {Permissions("reports").map(
              (item) =>
                item === userProfile.role && (
                  <Item
                    key={"/reports"}
                    title={"Reportes"}
                    icon={
                      <PieChartOutlined
                        style={{ fontSize: fontSize, color: color }}
                      />
                    }
                  >
                    <Link to="/reports" /> Reportes
                  </Item>
                )
            )}

            {userProfile.role === "admin" && (
              <Menu.SubMenu
                title="Admin"
                icon={
                  <ControlOutlined
                    style={{ fontSize: fontSize, color: color }}
                  />
                }
              >
                {Permissions("users").map(
                  (item) =>
                    item === userProfile.role && (
                      <Item
                        key={"/users"}
                        icon={
                          <TeamOutlined
                            style={{ fontSize: fontSize, color: color }}
                          />
                        }
                      >
                        <Link to="/users" />
                        Usuarios
                      </Item>
                    )
                )}

                {Permissions("companies").map(
                  (item) =>
                    item === userProfile.role && (
                      <Item
                        key={"/companies"}
                        icon={
                          <ShopOutlined
                            style={{ fontSize: fontSize, color: color }}
                          />
                        }
                      >
                        <Link to="/companies" />
                        Compañías
                      </Item>
                    )
                )}
              </Menu.SubMenu>
            )}

            <Item
              onClick={exit}
              key={uuidv4()}
              icon={
                <PoweroffOutlined
                  style={{ fontSize: fontSize, color: "#a61d24" }}
                />
              }
            >
              Salir
            </Item>
          </Menu>
        </motion.div>
      ) : (
        <div align="center" style={{ paddingTop: "8vh", paddingBottom: "3vh" }}>
          <Space direction="vertical">
            <Skeleton.Avatar active size={60} shape={"circle"} />
            <Skeleton paragraph={{ rows: 8 }} active />
          </Space>
        </div>
      )}
    </Sider>
  );
}
