import React, { useEffect, useState } from "react";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PatchMethod, PostMethod } from "../misc/CRUD";
import { Motion, Spin } from "../misc/Generic";
import { motion } from "framer-motion";
import { CustomTable, SendEmail } from "../misc/Generic";
import ResetPassword from "../misc/ResetPassword";
import moment from "moment";
import { CustomHeader } from "../misc/Generic";
import PasswdGenerator from "generate-password";
import {
  EditOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Select,
  Tag,
  Modal,
  Space,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  Tooltip,
  Typography,
  Collapse,
  Popover,
} from "antd";

const { Text } = Typography;
const { Panel } = Collapse;

export default function Users() {
  const { API, currentUser } = useGlobal();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(false);
  const [editData, seteditData] = useState();

  const [companies, setCompanies] = useState();

  const [form] = Form.useForm();
  //#region   //? =---> MISC

  const getData = () => {
    setSend(true);
    GetMethod(`${API}users/`)
      .then((res) => {
        GetMethod(`${API}companies/`).then((res) => {
          setCompanies(res);
        });

        setData(res);
        setLoading(true);
        setSend(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
        setSend(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    seteditData("");
    form.resetFields();

    form.setFieldsValue({ cambiarContrasena: true });

    setVisible(true);
  };

  const handleRefresh = () => {
    getData();
  };
  //#endregion

  //#region   //? =---> UPDATE || POST in onFinish())
  const handleEdit = (rowData) => {
    form.setFieldsValue({ cambiarContrasena: false });

    setVisible(true);
    seteditData(rowData);
  };

  const CustomizedForm = ({ onChange, fields }) => {
    const onFinish = async (values) => {
      //#region //? =---> UPDATE
      setVisible(false);
      setSend(true);
      if (editData) {
        let selectedCompany = editData.idCompany;
        companies.forEach((item) => {
          if (values.company === item._id) {
            selectedCompany = item._id;
          }
        });

        values = {
          ...values,
          company: selectedCompany,
          email: editData.email,
        };

        PatchMethod(`${API}users/${editData._id}`, values)
          .then((res) => {
            setSend(false);
            setData(res.allUsers);
            message.success("Actualizado 🎉");
          })
          .catch((err) => {
            setError(err);
            setSend(false);
          });
        //#endregion

        //#region //? =---> POST
      } else {
        const password = PasswdGenerator.generate({
          length: 10,
          numbers: true,
        });
        values = { ...values, password, status: "active" };
        PostMethod(`${API}users/`, values)
          .then((res) => {
            if (res === "User already exists") {
              message.error("Usuario ya esiste");
            } else {
              setSend(true);
              message.success("Usuario creado! 🎉");
              message.info(
                "Los accesos fueron enviados al correo el usuario!",
                15
              );
              const _emailData = {
                name: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password,
              };
              SendEmail("template_nidgi8c", _emailData);
              setData(res);
            }
            setSend(false);
          })
          .catch((err) => {
            setError(err);
            setSend(false);
          });
      }

      //#endregion
    };

    return (
      <Form
        form={form}
        onFinishFailed={() => message.error("Revise todos los campos", 3)}
        onFinish={onFinish}
        layout="vertical"
        fields={fields}
      >
        {/* <Tabs defaultActiveKey="1" centered> */}
        {/* <TabPane tab="Datos Personales" key="1"> */}
        <Row gutter={[24, 3]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item
              name="firstName"
              label="Nombre"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input
                disabled={
                  editData
                    ? editData.email.replace(/.*@/, "") === "aeropost.com"
                      ? true
                      : false
                    : false
                }
                style={{ borderRadius: 10 }}
              />
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item
              name="lastName"
              label="Apellido"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Input
                disabled={
                  editData
                    ? editData.email.replace(/.*@/, "") === "aeropost.com"
                      ? true
                      : false
                    : false
                }
                style={{ borderRadius: 10 }}
              />
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item
              name="role"
              label={
                <Space direction="horizontal">
                  <>
                    <Popover
                      title="Descripción de roles"
                      trigger="click"
                      placement="left"
                      content={
                        <div style={{ width: 300 }}>
                          <Collapse>
                            <Panel header="Administrador" key="1">
                              <ul>
                                <li>Usuarios</li>
                                <li>Compañías</li>
                                <li>Ver todas las bases de datos</li>
                              </ul>
                            </Panel>
                            <Panel header="Sucursal" key="2">
                              <ul>
                                <li>Gestiona ordenes de aeropost</li>
                              </ul>
                            </Panel>
                            <Panel header="Externo" key="3">
                              <ul>
                                <li>
                                  Manipular unicamente la compañía asignada
                                </li>
                                <li>Gestiona ordenes</li>
                              </ul>
                            </Panel>
                            <Panel header="Reportes" key="4">
                              <ul>
                                <li>
                                  Accede a los reportes de todas las compañías.
                                </li>
                              </ul>
                            </Panel>
                            <Panel header="Servicio al cliente" key="5">
                              <ul>
                                <li>Cambia estados</li>
                                <li>Lectura de las bases de datos</li>
                              </ul>
                            </Panel>
                            <Panel header="Mensajero" key="6">
                              <ul>
                                <li>
                                  Tiene acceso a todas las órdenes en estado
                                  "RUTA MENSAJERO"
                                </li>
                                <li>Solo puede crear POD</li>
                              </ul>
                            </Panel>
                          </Collapse>
                        </div>
                      }
                    >
                      <Button
                        size="small"
                        icon={<InfoCircleOutlined />}
                        type="link"
                      />
                    </Popover>
                  </>

                  <Text>Rol</Text>
                </Space>
              }
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              <Select>
                <Select.Option value="admin">Administrador</Select.Option>
                <Select.Option value="sucursal">Sucursal</Select.Option>
                <Select.Option value="externo">Externo</Select.Option>
                <Select.Option value="reportes">Reportes</Select.Option>
                <Select.Option value="mensajero">Mensajero</Select.Option>
                <Select.Option value="servicioCliente">
                  Servicio al Cliente
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item
              name="company"
              label="Compañía"
              rules={[{ required: true, message: "Campo requerido" }]}
            >
              {companies && (
                <Select>
                  {companies.map((item, i) => (
                    <Select.Option key={i} value={item._id}>
                      {item.company}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item
              name="status"
              label="Estado"
              rules={[
                {
                  required: editData ? true : false,
                  message: "Campo requerido",
                },
              ]}
            >
              <Select disabled={editData ? false : true}>
                <Select.Option value="new">Nuevo</Select.Option>
                <Select.Option value="active">Activo</Select.Option>
                <Select.Option value="inactive">Inactivo</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form.Item
              name="email"
              label="Correo"
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
                {
                  type: "email",
                  message: "Correo inválido",
                },
                {
                  pattern: !editData ? "^(?!.*aeropost.com).*$" : "",
                  message:
                    "Usuarios de aeropost deben iniciar sesión con GOOGLE",
                },
              ]}
            >
              <Input
                disabled={
                  editData
                    ? editData.email.replace(/.*@/, "") === "aeropost.com"
                      ? true
                      : true
                    : false
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {/* </TabPane> */}
        {/* 
          <TabPane
            tab={
              !enablePassword ? `Recuperar contraseña` : `Correo y contraseña`
            }
            key="2"
          >
            {editData ? (
              editData.email.replace(/.*@/, "") === "aeropost.com" ? (
                <Col span={24}>
                  <Text type="warning">
                    Usuarios de Aeropost deben iniciar sesión con Google
                  </Text>
                </Col>
              ) : null
            ) : null}

            <Row gutter={[48, 3]}>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Correo"
                  rules={[
                    {
                      required: true,
                      message: "Campo requerido",
                    },
                    {
                      type: "email",
                      message: "Correo inválido",
                    },
                    {
                      pattern:
                        // "^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(aeropost).com$",
                        "^(?!.*aeropost.com).*$",
                      message:
                        "Usuarios de aeropost deben iniciar sesión con GOOGLE",
                    },
                  ]}
                >
                  <Input
                    disabled={
                      editData
                        ? editData.email.replace(/.*@/, "") === "aeropost.com"
                          ? true
                          : true
                        : false
                    }
                  />
                </Form.Item>
              </Col>
              {!newUser ? (
                <Col span={12}>
                  <Form.Item name="email" label="Contraseña">
                    <ResetPassword
                      editEmail={editData.email}
                      label="Cambiar contraseña"
                      block={true}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={12}>
                  <Form.Item hasFeedback name="password" label="Contraseña">
                    <Input disabled={true} visibilityToggle={false} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </TabPane>
        
         */}
        {/* </Tabs> */}

        <div
          style={{
            textAlign: "center",
            paddingTop: 50,
          }}
        >
          <Button
            type="text"
            onClick={() => setVisible(false)}
            style={{ marginRight: 8, borderRadius: 40 }}
          >
            Cancelar
          </Button>
          <Button style={{ borderRadius: 40 }} type="primary" htmlType="submit">
            Enviar
          </Button>
        </div>
      </Form>
    );
  };

  const EditForm = () => {
    const [fields, setFields] = useState([
      {
        name: "firstName",
        value: editData ? editData.firstName : null,
      },

      {
        name: "lastName",
        value: editData ? editData.lastName : null,
      },
      {
        name: "segundoApellido",
        value: editData ? editData.segundoApellido : null,
      },
      {
        name: "role",
        value: editData ? editData.role : null,
      },
      {
        name: "email",
        value: editData ? editData.email : null,
      },
      {
        name: "status",
        value: editData ? editData.status : null,
      },
      {
        name: "company",
        value: editData ? editData.company : null,
      },
    ]);

    return (
      <CustomizedForm
        fields={fields}
        onChange={(newFields) => {
          setFields(newFields);
        }}
      />
    );
  };

  //#endregion

  //#region  //? =---> Table props
  const columns = [
    {
      width: 90,
      dataIndex: "_id",
      fixed: "left",
      render: (record, index) => (
        <Space direction="horizontal">
          <Tooltip placement="left" title="Editar">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEdit(index)}
              disabled={index.email === currentUser.email ? true : false}
            />
          </Tooltip>

          <ResetPassword
            fromAdmin={true}
            editEmail={index.email}
            block={false}
          />
        </Space>
      ),
    },
    {
      title: "Nombre",
      width: 190,
      render: (index, record) => (
        <span>{`${record.firstName} ${record.lastName} `}</span>
      ),
      sorter: (a, b) => a.firstName.length - b.lastName.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Correo",
      dataIndex: "email",
      width: 250,
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Compañía",
      dataIndex: "company",
      sorter: (a, b) => a.company.length - b.company.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Rol",
      sorter: (a, b) => a.role.length - b.role.length,
      sortDirections: ["descend", "ascend"],
      render: (item) => (
        <>
          {item.role === "admin" ? (
            <Row>
              <Col span={24}>
                <Tag color="lime">Administrador</Tag>
              </Col>
            </Row>
          ) : item.role === "servicioCliente" ? (
            "Servicio al cliente"
          ) : (
            item.role.charAt(0).toUpperCase() + item.role.slice(1)
          )}
        </>
      ),
    },
    {
      title: "Estado",
      align: "center",
      filters: [
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Inactivo",
          value: "inactive",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
      render: (item) =>
        item.status === "active" ? (
          <Tag color="lime">Activo</Tag>
        ) : item.status === "inactive" ? (
          <Tag color="volcano">Inactivo</Tag>
        ) : item.status === "new" ? (
          <Tooltip title="Asigne permisos">
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              Nuevo
            </Tag>
          </Tooltip>
        ) : null,
    },
    {
      title: "Verificado",
      align: "center",
      sorter: (a, b) => a.emailVerified - b.emailVerified,
      sortDirections: ["descend", "ascend"],
      render: (item) => (
        <>
          <Row>
            <Col span={24}>
              {item.emailVerified ? (
                <CheckCircleOutlined
                  style={{ fontSize: 20, color: "#A9D133" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ fontSize: 20, color: "#aa3e19" }}
                />
              )}
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Última vez",

      width: "auto",
      sorter: (a, b) => moment(a.lastSignInTime) - moment(b.lastSignInTime),

      render: (item) =>
        item.lastSignInTime ? (
          <Tooltip
            placement="top"
            title={moment(item.lastSignInTime).format("LLL")}
          >
            {moment(item.lastSignInTime).fromNow().charAt(0).toUpperCase() +
              moment(item.lastSignInTime).fromNow().slice(1)}
          </Tooltip>
        ) : (
          "Never"
        ),
    },
  ];
  //#endregion

  return (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      {error ? (
        <span>{error.message} </span>
      ) : (
        <>
          <CustomHeader label="Usuarios" />
          {!loading ? (
            <Spin />
          ) : (
            <>
              <Motion>
                <CustomTable
                  handleCreate={handleCreate}
                  handleRefresh={handleRefresh}
                  columns={columns}
                  send={loading ? send : send}
                  data={data}
                  showButton={true}
                />

                <Modal
                  width={700}
                  title={editData ? "Editar" : "Crear nuevo"}
                  onCancel={() => setVisible(false)}
                  visible={visible}
                  footer={null}
                >
                  <div align="center">
                    <EditForm />
                  </div>
                </Modal>
              </Motion>
            </>
          )}
        </>
      )}
    </motion.div>
  );
}
