import React, { useEffect, useState } from "react";
import {
  Alert,
  Select,
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Space,
} from "antd";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PatchMethod } from "../misc/CRUD";
import { motion } from "framer-motion";
import { CustomHeader } from "../misc/Generic";
import { Spin, Motion } from "../misc/Generic";
import ResetPassword from "../misc/ResetPassword";
import { SaveOutlined, ThunderboltOutlined } from "@ant-design/icons";
const { Option } = Select;

export default function Profile() {
  const { API, currentUser } = useGlobal();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const [send, setSend] = useState(false);
  const [form] = Form.useForm();
  const [branches, setBranches] = useState(false);

  //#region   //? =---> MISC

  useEffect(() => {
    let isMounted = true;

    GetMethod(`${API}users/${currentUser.email}`)
      .then((res) => {
        if (isMounted) {
          setData(res);
        }
      })
      .then(() => {
        GetMethod(`${API}branches`)
          .then((res) => {
            if (isMounted) {
              setBranches(res);
              setLoading(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((err) => {
        setLoading(true);
      });

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#endregion

  //#region   //? =--->  PUT in onFinish())

  const onFinish = async (values) => {
    setSend(true);

    values = {
      ...data,
      email: currentUser.email,
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.role,
      status: data.status,
      accountNumber: values.accountNumber,
      mobile: values.mobile,
      phone: values.phone,
      origin: values.origin,
    };

    PatchMethod(`${API}users/${data._id}`, values)
      .then((res) => {
        setSend(false);
        setData(res.profile);
        message.success("Actualizado 🎉");
      })
      .catch((err) => {
        setSend(false);
      });
  };

  // #endregion

  return (
    <>
      <CustomHeader label="Perfil" />
      {loading ? (
        <motion.div
          exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
        >
          <Motion>
            <div align="center" style={{ paddingTop: 40 }}>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                  accountNumber: data.accountNumber ? data.accountNumber : null,
                  mobile: data.mobile ? data.mobile : null,
                  phone: data.phone ? data.phone : null,
                  origin: data.origin ? data.origin : null,
                }}
              >
                <Row gutter={[24, 10]}>
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Col>
                      <Alert
                        message="Función de autocompletar"
                        description={
                          <Space direction="vertical">
                            Los campos que llenés en esta sección, se podrán ver
                            reflejados cuando presionés el botón autocompletar,
                            en la sección de "Nueva Orden".
                            <Button
                              size="large"
                              icon={
                                <ThunderboltOutlined
                                  style={{ fontSize: 30, color: "#f3ea62" }}
                                />
                              }
                              type="dashed"
                            />
                          </Space>
                        }
                        type="warning"
                        showIcon
                      />
                    </Col>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Col>
                      <Form.Item
                        hasFeedback
                        name="accountNumber"
                        label="Número de cuenta"
                        rules={[
                          {
                            pattern: "^[0-9]+$",
                            message: "Sólo se permiten números sin espacios",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        hasFeedback
                        name="mobile"
                        label="Celular"
                        rules={[
                          {
                            pattern: "^[0-9]+$",
                            message: "Sólo se permiten números sin espacios",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        hasFeedback
                        name="phone"
                        label="Teléfono"
                        rules={[
                          {
                            pattern: "^[0-9]+$",
                            message: "Sólo se permiten números sin espacios",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        hasFeedback
                        name="origin"
                        label="Sucursal de origen"
                      >
                        <Select
                          size="large"
                          showSearch
                          showArrow
                          style={{ width: "100%" }}
                        >
                          {branches.map((item, i) =>
                            item.name !== "Domicilio" ? (
                              <Option key={i} value={item.name}>
                                {item.name}
                              </Option>
                            ) : null
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Space wrap size="middle" align="center">
                        <ResetPassword
                          editEmail={currentUser.email}
                          label="Cambiar contraseña"
                          block={false}
                        />

                        <Button
                          type="dashed"
                          shape="round"
                          htmlType="submit"
                          icon={<SaveOutlined />}
                          loading={send}
                        >
                          Guardar cambios
                        </Button>
                      </Space>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </div>
          </Motion>
        </motion.div>
      ) : (
        <Spin />
      )}
    </>
  );
}
