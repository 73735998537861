const Status = [
  { name: "PEDIDO PROCESADO" },
  { name: "PREPARANDO ENVIO" }, // No se usa
  { name: "TRANSITO A SUCURSAL" },
  { name: "EN MANIFIESTO" },
  { name: "LISTO EN COUNTER" },
  { name: "ENTREGADO" },
  { name: "ALMACENADO" },
  { name: "DE VUELTA A REMITENTE" },
  { name: "DESTRUIDO" },
  { name: "RUTA MENSAJERO" },
  { name: "SEGUNDO INTENTO" },
  { name: "TERCER INTENTO" },
  { name: "INVALIDA" },
];

export default Status;
