import React, { useEffect, useState } from "react";
import { GetMethod, PatchMethod } from "../misc/CRUD";
import { useGlobal } from "../context/GlobalContext";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import Print from "../misc/Print";
import { Spin, SendEmail, CustomHeader, Motion } from "../misc/Generic";
import Events from "./Events";
import { UploadComment } from "../misc/UploadPhoto";
import { motion } from "framer-motion";
import {
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  SaveOutlined,
  SyncOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Tooltip,
  Drawer,
  Tabs,
  Button,
  BackTop,
  Card,
  Statistic,
  Descriptions,
  Image,
  Space,
  Select,
  Typography,
  message,
  Row,
  Col,
  Tag,
  Alert,
} from "antd";

const { Item } = Descriptions;
const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;

export default function DetailledOrder() {
  const { API, currentUser } = useGlobal();
  const [data, setData] = useState();
  const [error, setError] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [newStatus, setNewStatus] = useState();
  const [status, setStatus] = useState();
  const [events, setEvents] = useState();
  const [loadButton, setLoadButton] = useState(false);

  const [descriptionColumns] = useState({
    xxl: 1,
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  });

  //#region   //? =---> MISC
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getData = () => {
    GetMethod(`${API}users/${currentUser.email}`)
      .then((userProfile) => {
        GetMethod(`${API}orders/?id=${id}`).then((res) => {
          try {
            if (res[0].length > 0) {
              setError("Orden no entrada");
            } else {
              const _events = res[0].events.sort(
                (a, b) => b.createdAt - a.createdAt
              );
              setEvents(_events);
              if (res.length > 0) {
                localStorage.setItem("_print", JSON.stringify(res[0]));
                if (
                  userProfile.role === "mensajero" ||
                  userProfile.role === "admin" ||
                  userProfile.role === "sucursal"
                ) {
                  setNewStatus(res[0].status);
                  setData(res[0]);
                } else {
                  if (res[0].company === userProfile.company) {
                    setNewStatus(res[0].status);
                    setData(res[0]);
                  } else {
                    setError("No tiene permisos para ver esta orden");
                  }
                }
              } else {
                setError("Orden no encontrada");
              }
            }
          } catch (error) {
            setError("Orden no encontrada");
          }
        });
      })
      .then(() => {
        GetMethod(`${API}status`)
          .then((res) => {
            setStatus(res);
          })
          .catch((e) => {
            console.log("EERRRROOOORRR=>", e);
          });
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getData();
      localStorage.removeItem("_photo");
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatus = (oldStatus, _newStatus) => {
    setLoadButton(true);
    const values = {
      status: _newStatus,
      tracking: [{ status: _newStatus, date: Date.now() }],
      events: [
        {
          comment: `Cambió de ${oldStatus} a ${_newStatus}`,
          createdBy: currentUser.displayName,
          createdAt: Date.now(),
          email: currentUser.email,
          userPhoto: currentUser.photoURL,
        },
      ],
    };

    PatchMethod(`${API}orders/${id}`, values)
      .then(() => {
        message.success("Estado actualizado");
        setShowDrawer(false);
        getData();
        const _emailData = {
          _id: data._id,
          senderName: data.senderName,
          senderLastName: data.senderLastName,
          consigneeName: data.consigneeName,
          consigneeLastName: data.consigneeLastName,
          description: data.description,
          consigneeEmail: data.consigneeEmail,
          weight: data.weight,
          cost: data.cost,
        };
        if (_newStatus === "LISTO EN COUNTER") {
          console.log("LISTO EN COUNTER");
          SendEmail("template_0dxqbvo", {
            ..._emailData,
            branch:
              data.destination === "Domicilio" ? "Aeropost" : data.destination,
          });
        } else if (_newStatus === "RUTA MENSAJERO") {
          SendEmail("template_44jm5zi", _emailData);
        }
        setLoadButton(false);
      })
      .catch((_) => {
        setLoadButton(false);
      });
  };

  const handleChangeStatus = (e) => {
    setNewStatus(e);
    setShowDrawer(true);
  };
  //#endregion
  return error ? (
    <Motion>
      <Alert message={error} type="warning" showIcon />
    </Motion>
  ) : !data || !status ? (
    <Spin />
  ) : (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      <Motion>
        <CustomHeader label="Detalle" />

        <Row gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xxl={{ span: 8 }}
          >
            <Card
              hoverable
              title={<Tag>{`Creado por: ${data.creatorName}`}</Tag>}
            >
              <Row gutter={[24, 12]} align="center">
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xxl={{ span: 24 }}
                >
                  <Title level={3}>{data._id}</Title>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xxl={{ span: 12 }}
                >
                  <Statistic title="Estado" value={data.status} />
                </Col>

                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xxl={{ span: 12 }}
                >
                  <Statistic
                    title="Valor"
                    prefix="¢"
                    value={numberWithCommas(data.cost)}
                  />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 24 }}
                  xxl={{ span: 12 }}
                >
                  <Print block={false} />
                </Col>

                <Col
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 24 }}
                  xxl={{ span: 12 }}
                >
                  <Button
                    icon={<SyncOutlined />}
                    type="primary"
                    onClick={() => {
                      getData();
                      message.success("Eventos actualizados");
                    }}
                  >
                    Refrescar
                  </Button>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xxl={{ span: 24 }}
                >
                  <Select
                    style={{ width: 190 }}
                    defaultValue={data.status}
                    value={newStatus}
                    onChange={handleChangeStatus}
                  >
                    {status.map((item, i) => (
                      <Option key={i} value={item.name}>
                        <Tooltip title={item.name} placement="rightTop">
                          {item.name}
                        </Tooltip>
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 14 }}
            xxl={{ span: 14 }}
          >
            <Card hoverable>
              <Tabs defaultActiveKey="2">
                <TabPane tab="Remitente" key="1">
                  <Descriptions
                    // style={{ paddingTop: 40 }}
                    bordered
                    column={descriptionColumns}
                  >
                    <Item label="Cuenta">{data.accountNumber}</Item>
                    <Item label="Remitente">{`${data.senderName} ${data.senderLastName}`}</Item>

                    <Item label="Contacto">
                      {
                        <Item>
                          <Tooltip title="Enviar WhatsApp">
                            <Link
                              to={{
                                pathname: `https://wa.me/+506${data.senderMobile}`,
                              }}
                              target="_blank"
                            >
                              {data.senderMobile}
                            </Link>
                          </Tooltip>

                          <br />
                          {data.senderPhone ? data.senderPhone : null}
                        </Item>
                      }
                    </Item>

                    <Item label="Correo">{data.senderEmail}</Item>

                    {data.photo && (
                      <Item label="Foto de ingreso">
                        <Image
                          width={100}
                          src={data.photo}
                          alt={data.photo}
                          style={{ borderRadius: 10 }}
                        />
                      </Item>
                    )}
                  </Descriptions>
                </TabPane>
                <TabPane tab="Consignatario - Paquete" key="2">
                  <Descriptions
                    //style={{ paddingTop: 40 }}
                    bordered
                    column={descriptionColumns}
                  >
                    <Item label="Consignatario">{`${data.consigneeName} ${data.consigneeLastName}`}</Item>
                    <Item label="Cédula">{data.consigneeId}</Item>
                    <Item label="Contacto">
                      {
                        <Item>
                          <Space direction="vertical">
                            <Tooltip title="Enviar WhatsApp">
                              <Link
                                to={{
                                  pathname: `https://wa.me/+506${data.consigneeMobile}`,
                                }}
                                target="_blank"
                              >
                                {data.consigneeMobile}
                              </Link>
                            </Tooltip>

                            {data.consigneeEmail}

                            {data.consigneePhone ? data.consigneePhone : null}
                          </Space>
                        </Item>
                      }
                    </Item>

                    <Item label="Origen">{data.originStore}</Item>
                    <Item label="Destino">{data.destination}</Item>
                    {data.destination === "Domicilio" ? (
                      <>
                        <Item label="Dirección exacta">
                          {data.coverage.join(" / ")}
                          <br />
                          {data.address}
                        </Item>
                      </>
                    ) : null}
                    <Item label="Peso"> {data.weight} kg</Item>
                    <Item label="Creación">
                      {moment(data.date).format("LLL")}
                    </Item>
                    <Item label="Descripción">{data.description}</Item>
                  </Descriptions>
                </TabPane>
                <TabPane tab="Eventos" key="3">
                  <Events data={events} orderId={id} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>

        <Drawer
          title={
            <Space>
              <Title level={3}>
                <ExclamationCircleOutlined
                  style={{ color: "#d89614", fontSize: 25 }}
                />
                {` Cambio de estado`}
              </Title>
            </Space>
          }
          placement="top"
          height="100vh"
          onClose={() => {
            setShowDrawer(false);
            setNewStatus(data.status);
          }}
          visible={showDrawer}
        >
          <div align="center">
            <Space direction="vertical" style={{ paddingTop: 10 }}>
              <Statistic
                title="Antes"
                value={data.status}
                prefix={
                  <ArrowLeftOutlined
                    style={{ color: "#aa3e19", fontSize: 25 }}
                  />
                }
              />
              <Statistic
                title="Después"
                value={newStatus}
                prefix={
                  <ArrowRightOutlined
                    style={{ color: "#A9D133", fontSize: 25 }}
                  />
                }
              />
            </Space>
            <div style={{ paddingTop: 30 }}>
              {newStatus === "ENTREGADO" ? (
                <Space size={100} direction="vertical">
                  <UploadComment
                    component="detailled"
                    orderId={id}
                    commentRequired={false}
                    photoRequired={true}
                    showUploadPhoto={true}
                    newStatus={newStatus}
                    oldStatus={data.status}
                  />
                  <Button
                    style={{ width: 200 }}
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => setShowDrawer(false)}
                  >
                    Cancelar
                  </Button>
                </Space>
              ) : (
                <Space size="large">
                  <Button
                    style={{ width: 200 }}
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => setShowDrawer(false)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    loading={loadButton}
                    style={{ width: 200 }}
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => updateStatus(data.status, newStatus)}
                  >
                    Guardar
                  </Button>
                </Space>
              )}
            </div>
          </div>
        </Drawer>

        <BackTop style={{ marginRight: -35 }} />
      </Motion>
    </motion.div>
  );
}
