import React, { useState, useEffect } from "react";
import { useGlobal } from "../context/GlobalContext";
import { Redirect } from "react-router-dom";
import { Spin } from "../misc/Generic";
import axios from "axios";
import { message } from "antd";
import { Form, Input, Button, Row, Col, Typography, Modal } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import LoginPhoto from "../images/loginphoto.png";
import localDelivery from "../images/localDelivery.png";
import { Copyright } from "../misc/Generic";
import { motion, AnimatePresence } from "framer-motion";
import { MetroSpinner } from "react-spinners-kit";

const { Link } = Typography;

export default function Login() {
  const { API, login, currentUser, loginWithGoogle, resetPassword } =
    useGlobal();
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState("");
  const [email, setEmail] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [initializing, setInializing] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    setTimeout(() => {
      setInializing(false);
    }, 1000);
  }, []);

  const verifyDomain = (value) => {
    var email = value.target.value;
    var formDomain = email.replace(/.*@/, "");
    if (formDomain.toLowerCase() === "aeropost.com") {
      setDomain(true);
    } else {
      setDomain(false);
    }
  };

  const handleForgetPassword = () => {
    setEmail("");
    setOpenModal(true);
  };

  const requestLogin = async (values) => {
    setLoading(true);
    await axios
      .get(`${API}login/${values.email}`)
      .then(function (res) {
        login(values.email, values.password);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const handleOk = () => {
    setLoading(true);
    var formDomain = email.replace(/.*@/, "");
    if (formDomain.toLowerCase() === "aeropost.com") {
      message.warn("Inicia sesión con Google");
      setLoading(false);
    } else {
      message.success("Enviamos la información a tu correo!");
      setOpenModal(false);
      setLoading(false);
      resetPassword(email);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };

  return currentUser ? (
    <Redirect to="/" />
  ) : (
    <>
      <AnimatePresence exitBeforeEnter>
        {initializing && (
          <div align="center">
            <motion.div
              initial={{ y: "50vh", opacity: 0 }}
              animate={{ y: "40vh", opacity: 1 }}
              transition={{
                delay: 0,
                type: "spring",
                stiffness: 100,
              }}
              exit={{
                y: "-10vh",
                opacity: 0,
                transition: { ease: "easeInOut" },
              }}
            >
              <MetroSpinner color="#9fc6e6" size={100} />
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 1.3, type: "spring", stiffness: 100 }}
      >
        <Modal
          width={400}
          title="Recuperar contraseña"
          visible={openModal}
          onOk={handleOk}
          onCancel={onCancel}
          confirmLoading={loading}
        >
          <Form
            onFinish={handleOk}
            initialValues={{
              _email: "",
            }}
          >
            <Form.Item
              name="_email"
              hasFeedback
              onChange={(e) => setEmail(e.target.value)}
              rules={[
                {
                  required: true,
                  message: "Ingrese su correo electrónico",
                },
                {
                  type: "email",
                  message: "Correo inválido",
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Correo" />
            </Form.Item>
          </Form>
        </Modal>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0, type: "tween", stiffness: 120 }}
              style={{
                backgroundImage: `url(${LoginPhoto})`,
                backgroundRepeat: "no-repeat",
                height: "100vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            align="center"
            style={{ paddingBlock: "10vh" }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ y: 20, opacity: 1 }}
              transition={{ delay: 0.7, type: "spring", stiffness: 120 }}
            >
              <img
                src={localDelivery}
                alt="logo"
                style={{ paddingBlock: "6vh" }}
                width={300}
              />
            </motion.div>

            <Form onFinish={requestLogin} name="login" key="b">
              <>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ y: 20, opacity: 1 }}
                  transition={{ delay: 0.8, type: "spring", stiffness: 120 }}
                >
                  <Form.Item
                    hasFeedback
                    name="email"
                    onChange={verifyDomain}
                    form={form}
                    style={{ width: "80%" }}
                    rules={[
                      {
                        required: true,
                        message: "Ingrese su correo electrónico",
                      },
                      {
                        type: "email",
                        message: "Correo inválido",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      prefix={<UserOutlined />}
                      placeholder="Correo"
                    />
                  </Form.Item>
                </motion.div>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.email !== currentValues.email
                  }
                >
                  {() =>
                    domain === true ? (
                      <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ y: 20, opacity: 1 }}
                        transition={{
                          delay: 0.1,
                          type: "spring",
                          stiffness: 120,
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() => loginWithGoogle()}
                        >
                          Continuar con Google
                        </Button>
                      </motion.div>
                    ) : (
                      <>
                        <motion.div
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ y: 20, opacity: 1 }}
                          transition={{
                            delay: 0.9,
                            type: "spring",
                            stiffness: 120,
                          }}
                        >
                          <Form.Item
                            name="password"
                            hasFeedback
                            style={{ width: "80%" }}
                            rules={[
                              {
                                required: true,
                                message: "Ingrese su contraseña",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              prefix={<LockOutlined />}
                              type="password"
                              placeholder="Contraseña"
                            />
                          </Form.Item>
                        </motion.div>
                        <br />
                        {loading ? (
                          <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ y: -10, opacity: 1 }}
                            transition={{
                              delay: 0,
                              type: "spring",
                              stiffness: 120,
                            }}
                          >
                            <Spin />
                          </motion.div>
                        ) : (
                          <>
                            <motion.div
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ y: 20, opacity: 1 }}
                              transition={{
                                delay: 1,
                                type: "spring",
                                stiffness: 120,
                              }}
                            >
                              <Button
                                type="primary"
                                style={{ width: 250 }}
                                htmlType="submit"
                              >
                                Iniciar Sesión
                              </Button>
                            </motion.div>

                            <motion.div
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ y: 40, opacity: 1 }}
                              transition={{
                                delay: 1.1,
                                type: "spring",
                                stiffness: 120,
                              }}
                            >
                              <Form.Item>
                                <Link onClick={handleForgetPassword}>
                                  Olvidé mi contraseña
                                </Link>
                              </Form.Item>
                            </motion.div>
                          </>
                        )}
                      </>
                    )
                  }
                </Form.Item>
              </>
            </Form>
            {!loading && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ y: 40, opacity: 1 }}
                transition={{
                  delay: 1.2,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <Copyright />
              </motion.div>
            )}
          </Col>
        </Row>
      </motion.div>
    </>
  );
}
