import React, { useState, useEffect } from "react";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PostMethod } from "../misc/CRUD";
import { Provincias } from "../misc/Provincias";
import Print from "../misc/Print";
import { SendEmail } from "../misc/Generic";
import { CustomHeader } from "../misc/Generic";
import { UploadPhoto } from "../misc/UploadPhoto";
import { Spin } from "../misc/Generic";
import { motion, AnimatePresence } from "framer-motion";
import {
  ThunderboltOutlined,
  ClearOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Select,
  Modal,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  Cascader,
  Tabs,
  Space,
  Tooltip,
  Result,
} from "antd";

const { TabPane } = Tabs;
const { Option } = Select;

export default function Orders() {
  const { API, currentUser } = useGlobal();
  const [loadInput, setloadInput] = useState(false);
  const [branches, setBranches] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  //#region   //? =---> MISC

  useEffect(() => {
    let isMounted = true;
    localStorage.removeItem("_photo");

    GetMethod(`${API}branches`)
      .then((res) => {
        if (isMounted) {
          setBranches(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [API]);

  const autoComplete = () => {
    setloadInput(true);
    GetMethod(`${API}users/${currentUser.email}`).then((res) => {
      form.setFieldsValue({
        accountNumber: res.accountNumber ? res.accountNumber : null,
        senderName: res.firstName ? res.firstName : null,
        senderLastName: res.lastName ? res.lastName : null,
        originStore: res.origin ? res.origin : null,
        senderPhone: res.phone,
        senderMobile: res.mobile ? res.mobile : null,
        senderEmail: res.email ? res.email : null,
      });
      setloadInput(false);
    });
  };

  //#endregion

  //#region   //? =---> PRINT
  function showSuccess(id) {
    Modal.info({
      centered: true,
      icon: "",
      okText: "Cerrar",
      content: (
        <Result
          status="success"
          title="Orden creada con éxito"
          subTitle={`Orden número: ${id}`}
          extra={[<Print block={true} />]}
        />
      ),
    });
  }
  //#endregion

  //#region   //? =--->  POST in onFinish())

  const onFinish = async (values) => {
    if (values.consigneeId && values.weight) {
      setSubmitting(true);
      try {
        GetMethod(`${API}users/${currentUser.email}`).then((_res) => {
          values = {
            ...values,
            photo: localStorage.getItem("_photo"),
            company: _res.company,
            status: "PEDIDO PROCESADO",
            creatorEmail: _res.email,
            creatorPhoto: _res.photoURL,
            creatorName: `${_res.firstName} ${_res.lastName}`,
            createdAt: Date.now(),
            tracking: [
              {
                status: "PEDIDO PROCESADO",
                date: Date.now(),
              },
            ],
          };

          PostMethod(`${API}orders`, values)
            .then((res) => {
              localStorage.setItem("_print", JSON.stringify(res));
              SendEmail("template_hdy9vtp", { ...values, _id: res._id });

              setSubmitting(false);
              showSuccess(res._id);
            })
            .catch((error) => {
              alert("Error", error);
              setSubmitting(false);

              console.log(error);
            });
        });
      } catch (error) {
        alert("Error", error);
        setSubmitting(false);

        console.log(error);
      }
    } else {
      message.error("Revisa todos los campos");
    }
  };

  // #endregion

  return (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      <CustomHeader label="Nueva Orden" />
      {!branches ? (
        <Spin />
      ) : (
        <>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={() => {
              message.error("Revisa todos los campos");
            }}
          >
            <AnimatePresence>
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 20, x: 0, opacity: 1 }}
                transition={{ delay: 0, type: "spring", stiffness: 120 }}
              >
                <div align="center">
                  <Space wrap size="large">
                    <Tooltip title="Autocompletar">
                      <Button
                        size="large"
                        icon={
                          <ThunderboltOutlined
                            style={{ fontSize: 30, color: "#f3ea62" }}
                          />
                        }
                        onClick={autoComplete}
                        type="dashed"
                      />
                    </Tooltip>
                    <Tooltip title="Limpiar">
                      <Button
                        size="large"
                        type="dashed"
                        icon={
                          <ClearOutlined
                            style={{ fontSize: 30, color: "#65b7f3" }}
                          />
                        }
                        onClick={() => form.resetFields()}
                        htmlType="submit"
                      />
                    </Tooltip>

                    <Tooltip title="Guardar">
                      <Button
                        loading={submitting}
                        type="dashed"
                        htmlType="submit"
                        size="large"
                        icon={
                          <SaveOutlined
                            style={{ fontSize: 30, color: "#8fd460" }}
                          />
                        }
                      />
                    </Tooltip>
                  </Space>
                </div>

                <Tabs defaultActiveKey="1" centered type="line">
                  <TabPane tab="Remitente" key="1">
                    <Row gutter={[48, 3]} style={{ padding: 35 }}>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="accountNumber"
                          label="Número de cuenta"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            {
                              pattern: "^[0-9]+$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input disabled={loadInput} size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="senderName"
                          label="Nombre"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Input disabled={loadInput} size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="senderLastName"
                          label="Apellidos"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Input disabled={loadInput} size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="senderMobile"
                          label="Celular"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            {
                              pattern: "^[0-9]+$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input disabled={loadInput} size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="senderPhone"
                          label="Telefóno"
                          rules={[
                            {
                              pattern: "^[0-9]+$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input disabled={loadInput} size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="senderEmail"
                          label="Correo"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            { type: "email", message: "Correo inválido" },
                          ]}
                        >
                          <Input disabled={loadInput} size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        xxl={{ span: 12 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="originStore"
                          label="Origen"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Select
                            disabled={loadInput}
                            size="large"
                            showSearch
                            showArrow
                          >
                            {branches.map((e, i) =>
                              e.name !== "Domicilio" ? (
                                <Option key={i} value={e.name}>
                                  {e.name}
                                </Option>
                              ) : null
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tab="Consignatario" key="2">
                    <Row gutter={[48, 3]} style={{ padding: 35 }}>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="consigneeId"
                          label="Cédula"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            {
                              pattern: "^[0-9]+$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="consigneeName"
                          label="Nombre"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="consigneeLastName"
                          label="Apellidos"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="consigneeMobile"
                          label="Celular"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            {
                              pattern: "^[0-9]+$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="consigneePhone"
                          label="Telefóno"
                          rules={[
                            {
                              pattern: "^[0-9]+$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 6 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="consigneeEmail"
                          label="Correo"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            { type: "email", message: "Correo inválido" },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xxl={{ span: 12 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="destination"
                          label="Destino"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Select size="large" showSearch showArrow>
                            {branches.map((e, i) =>
                              e.name !== "Pickup" ? (
                                <Option key={i} value={e.name}>
                                  {e.name}
                                </Option>
                              ) : null
                            )}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.destination !== currentValues.destination
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("destination") === "Domicilio" ? (
                            <>
                              <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 12 }}
                                xxl={{ span: 12 }}
                              >
                                <Form.Item
                                  name="coverage"
                                  label="Cobertura"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <Cascader
                                    size="large"
                                    options={Provincias}
                                    placeholder="Provincia / Cantón / Distrito"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xxl={{ span: 12 }}
                              >
                                <Form.Item
                                  hasFeedback
                                  name="address"
                                  label="Dirección exacta"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Campo requerido",
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    size="large"
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null
                        }
                      </Form.Item>
                    </Row>
                  </TabPane>
                  <TabPane tab="Paquete" key="3">
                    <Row gutter={[48, 3]} style={{ padding: 35 }}>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 8 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="weight"
                          label="Peso"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            {
                              pattern: "^[0-9.]*$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            addonBefore="kg"
                            placeholder="Ej: 0.40"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 8 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="cost"
                          label="Valor"
                          rules={[
                            { required: true, message: "Campo requerido" },
                            {
                              pattern: "^[0-9.]*$",
                              message: "Sólo se permiten números sin espacios",
                            },
                          ]}
                        >
                          <Input size="large" addonBefore="¢" />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                        xxl={{ span: 8 }}
                      >
                        <Form.Item
                          hasFeedback
                          name="description"
                          label="Descripción"
                          rules={[
                            { required: true, message: "Campo requerido" },
                          ]}
                        >
                          <Input.TextArea
                            size="large"
                            autoSize={{ minRows: 1, maxRows: 6 }}
                            showCount
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        xxl={{ span: 24 }}
                      >
                        <Form.Item valuePropName="fileList" name="imagen">
                          <UploadPhoto />
                        </Form.Item>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </motion.div>
            </AnimatePresence>
          </Form>
        </>
      )}
    </motion.div>
  );
}
