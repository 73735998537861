import React, { useState } from "react";
import { PatchMethod } from "../misc/CRUD";
import { useGlobal } from "../context/GlobalContext";
import {
  ClearOutlined,
  SaveOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { storage } from "../context/firebase";
import { motion } from "framer-motion";
import {
  Upload,
  Space,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  Alert,
} from "antd";
import { v4 as uuidv4 } from "uuid";

export function UploadPhoto({ listType, orderId }) {
  const [progress, setProgress] = useState(0);
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    showUploadList: true,
    customRequest: async ({ onSuccess, onError, file }) => {
      // const fileName = file.name;

      console.log(file);
      const uploadTask = storage
        .ref(`images/${file.name + file.uid}`)
        .put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        (item) => {
          storage
            .ref(`images`)
            .child(file.name + file.uid)
            .getDownloadURL()
            .then((url) => {
              localStorage.setItem("_photo", url);
              onSuccess(url);
            })
            .catch((e) => {
              onError(e);
            });
        }
      );
    },
  };

  function beforeUpload(file) {
    const formarValidation =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!formarValidation) {
      message.error("Formatos permitidos JPG/PNG/JPG", 4);
    }
    const isLt5M = file.size < 5242880; //5mb

    if (!isLt5M) {
      message.error("Debe ser menor a 5mb!");
    }
    return formarValidation && isLt5M;
  }

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <div align="center">
      <Upload
        {...draggerProps}
        onChange={onChange}
        beforeUpload={beforeUpload}
        onPreview={onPreview}
        accept=".png, .jpg, .jpeg"
        maxCount={1}
        listType={listType === "picture-card" ? "picture-card" : "picture-card"}
      >
        {fileList.length < 5 && (
          <Space direction="vertical">
            <FileImageOutlined style={{ fontSize: 40 }} />
            <span>Subir foto</span>
          </Space>
        )}
      </Upload>
    </div>
  );
}

export function UploadComment({
  component,
  orderId,
  commentRequired,
  photoRequired,
  newStatus,
  oldStatus,
  showUploadPhoto,
}) {
  const [form] = Form.useForm();
  const { API, currentUser } = useGlobal();
  const [showAlerts, setShowAlerts] = useState();
  const [loadButton, setLoadButton] = useState();

  const onFinish = async (values) => {
    if (photoRequired && !localStorage.getItem("_photo")) {
      message.error("Foto requerida para POD", 5);
    } else {
      setLoadButton(true);

      let comment;
      if (newStatus === "ENTREGADO") {
        comment = `Cambió de ${oldStatus} a ${"ENTREGADO"}`;
        if (values.comment) {
          comment = comment + ` Observaciones: ${values.comment}`;
        }
      } else {
        comment = values.comment;
      }
      values = {
        status: newStatus,
        events: [
          {
            comment,
            createdBy: currentUser.displayName,
            createdAt: Date.now(),
            email: currentUser.email,
            userPhoto: currentUser.photoURL,
            photo: localStorage.getItem("_photo") || null,
          },
        ],
        tracking: [{ status: newStatus, date: Date.now() }],
      };
      PatchMethod(`${API}orders/${orderId}`, values)
        .then(() => {
          if (component === "detailled") {
            setShowAlerts(true);
            setTimeout(() => {
              window.location.reload();
              localStorage.removeItem("_photo");
              setLoadButton(false);
            }, 2000);
          } else {
            setLoadButton(false);
            message.success("Orden actualizada");
          }
          form.resetFields();
        })
        .catch((_) => {
          console.log(_);
          setLoadButton(false);
          localStorage.removeItem("_photo");
        });
    }
  };

  return (
    <div>
      <>
        {showAlerts ? (
          <motion.div
            initial={{ y: -40, opacity: 0 }}
            animate={{ y: 10, opacity: 1 }}
            transition={{ delay: 0, type: "spring", stiffness: 120 }}
          >
            <Alert
              message="Orden actualizada, refrescando..."
              type="success"
              showIcon
            />
          </motion.div>
        ) : (
          <Form form={form} onFinish={onFinish}>
            <Row align="center" gutter={[18, 3]}>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="comment"
                  rules={[
                    { required: commentRequired, message: "Campo requerido" },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Agrega un comentarrio"
                    size="large"
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>

              {showUploadPhoto ? (
                <Col span={24}>
                  <Form.Item hasFeedback name="image" valuePropName="fileList">
                    <UploadPhoto orderId={orderId} />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
            <div
              style={{
                textAlign: "center",
                paddingBottom: 25,
              }}
            >
              <Space size="large">
                <Button
                  icon={<ClearOutlined />}
                  onClick={() => {
                    form.resetFields();
                    localStorage.removeItem("_photo");
                  }}
                >
                  Limpiar
                </Button>
                <Button
                  loading={loadButton}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Guardar
                </Button>
              </Space>
            </div>
          </Form>
        )}
      </>
    </div>
  );
}
