import React from "react";
import moment from "moment";
import { UploadComment } from "../misc/UploadPhoto";
import { Empty, Image, List, Avatar, Tooltip } from "antd";
import { useLocation } from "react-router-dom";

export default function Events({ data }) {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("id");

  return (
    <div>
      {data.length > 0 ? (
        <List
          pagination={{
            pageSize: 3,
          }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  item.userPhoto ? (
                    <Tooltip placement="top" title={item.email}>
                      <Avatar size="large" src={item.userPhoto} />
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title={item.email}>
                      <Avatar size="large">
                        {`${item.createdBy
                          .split(" ")
                          .slice(0, -1)
                          .join("")
                          .substring(0, 1)}${item.createdBy
                          .split(" ")
                          .slice(-1)
                          .join("")
                          .substring(0, 1)}`}
                      </Avatar>
                    </Tooltip>
                  )
                }
                title={
                  <Tooltip placement="top" title={item.email}>
                    {item.createdBy}
                  </Tooltip>
                }
                description={
                  <>
                    {moment(item.createdAt).format("LLL")}
                    <br />
                    {item.comment}
                  </>
                }
              />

              {item.photo ? (
                <Image
                  width={70}
                  src={item.photo}
                  alt={item.photo}
                  style={{ borderRadius: 10 }}
                />
              ) : null}
            </List.Item>
          )}
        />
      ) : (
        <div style={{ padding: 10 }}>
          <Empty description="No hay comentarios" />
        </div>
      )}
      <div style={{ paddingTop: 10 }}>
        <UploadComment
          component="events"
          orderId={id}
          commentRequired={true}
          photoRequired={false}
          showUploadPhoto={true}
        />
      </div>
    </div>
  );
}
