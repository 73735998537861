import React, { useState, useEffect } from "react";
import { Layout, Space } from "antd";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod } from "../misc/CRUD";
import Sidebar from "./Sidebar";
import Verify from "../login/Verify";
import Body from "./Body";
import { motion } from "framer-motion";
import Logo from "../images/logoCeleste.png";
import { Copyright, ProgressBar } from "../misc/Generic";
import WaitingAccess from "../misc/WaitingAccess";

const { Content, Footer } = Layout;

export default function Dashboard() {
  const { currentUser, API } = useGlobal();
  const [status, setStatus] = useState("");

  useEffect(() => {
    let isCancelled = false;
    GetMethod(`${API}users/${currentUser.email}`).then((res) => {
      if (!isCancelled) {
        res._id ? setStatus(res.status) : window.location.reload();
      }
    });
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !currentUser.emailVerified ? (
    <Verify />
  ) : status ? (
    status === "active" ? (
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout>
          <Content style={{ paddingRight: 15, paddingLeft: 15 }}>
            <Body />
          </Content>
          <Footer style={{ textAlign: "center", paddingTop: "10vh" }}>
            <div align="center">
              <motion.div
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0,
                  type: "spring",
                  stiffness: 120,
                }}
              >
                <Space direction="vertical">
                  <img src={Logo} alt="logo" width={150} />
                  <Copyright />
                </Space>
              </motion.div>
            </div>
          </Footer>
        </Layout>
      </Layout>
    ) : (
      <WaitingAccess />
    )
  ) : (
    <div align="center">
      <ProgressBar />
    </div>
  );
}
