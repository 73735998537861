import React, { createContext, useContext, useState, useEffect } from "react";
import { message } from "antd";
import { auth } from "./firebase";
import { PostMethod } from "../misc/CRUD";
import firebase from "firebase";

const GlobalContext = createContext();

export function useGlobal() {
  return useContext(GlobalContext);
}

export function GlobalProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [photo, setPhoto] = useState();
  const [loading, setLoading] = useState(true);

  // const API = process.env.REACT_APP_API;
  const API = "https://wkj9uwksx6.execute-api.us-west-2.amazonaws.com/dev/api/";
  // const API = "http://localhost:4000/api/";

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [currentUser]);

  function login(correo, contrasena) {
    return auth.signInWithEmailAndPassword(correo, contrasena).catch((err) => {
      // console.clear();
      console.log(err);
      return message.error("Usuario o contraseña incorrectos");
    });
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password).then((user) => {
      user.sendEmailVerification();
    });
  }

  // function updatePassword(password) {
  //   return currentUser.updatePassword(password);
  // }

  async function verifyEmail() {
    return auth.currentUser.sendEmailVerification();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let response = result.user;
        let aeropostUser = response.email.replace(/.*@/, "");

        if (aeropostUser !== "aeropost.com") {
          result.user.delete();
          return message.error("Cuenta de Aeropost inválida", 30);
        } else {
          var firstName = response.displayName
            .split(" ")
            .slice(0, -1)
            .join(" ");
          var lastName = response.displayName.split(" ").slice(-1).join(" ");

          let _user = {
            email: response.email,
            firstName: firstName,
            lastName: lastName,
            uid: response.uid,
            role: "sucursal",
            company: "6083824499c833c11dccbe84",
          };

          PostMethod(`${API}users/`, _user).then(() => {});
        }
      });
  }

  function logout() {
    auth.signOut();
  }

  const value = {
    loginWithGoogle,
    photo,
    setPhoto,
    signup,
    verifyEmail,
    resetPassword,
    login,
    logout,
    currentUser,
    API,
  };

  return (
    <GlobalContext.Provider value={value}>
      {!loading && children}
    </GlobalContext.Provider>
  );
}
