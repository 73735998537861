import React from "react";
import "./App.css";
import Landing from "../routes/Landing";
import { GlobalProvider } from "../context/GlobalContext";

export default function App() {
  return (
    <GlobalProvider>
      <Landing />
    </GlobalProvider>
  );
}
