import React, { useState } from "react";
import { Typography, Card, Row, Col, Button } from "antd";
import { useGlobal } from "../context/GlobalContext";
import localDelivery from "../images/localDelivery.png";
import { Copyright } from "../misc/Generic";
import { MailOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
const { Text, Title } = Typography;

export default function Verify() {
  const { currentUser, verifyEmail } = useGlobal();
  const [emailSent, setEmailSent] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const handleSendMail = () => {
    verifyEmail();
    setEmailSent(true);
  };
  return (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      <div align="center" style={{ paddingTop: 40 }}>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 20, opacity: 1 }}
          transition={{ delay: 0.9, type: "spring", stiffness: 120 }}
        >
          <img
            src={localDelivery}
            alt="logo"
            style={{ paddingBlock: "2vh" }}
            width={250}
          />
        </motion.div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 20, opacity: 1 }}
          transition={{ delay: 1, type: "spring", stiffness: 120 }}
        >
          <Card style={{ width: "80%" }} align="center">
            <Title level={3}>
              <Text>¡Hola </Text>
              <Text
                style={{ color: "#9FC6E6" }}
              >{`${currentUser.displayName}`}</Text>
              , te damos la bienvenida!
            </Title>
            <br />

            {!emailSent ? (
              <>
                <Text>Por seguridad requerimos validar su identidad.</Text>
                <br />
                <Text style={{ color: "#9FC6E6" }}>
                  Por favor haga click en el botón VERIFICAR
                </Text>
                <Text> para continuar.</Text>
              </>
            ) : (
              <>
                <Text>Hemos enviado un correo a la dirección </Text>
                <Text style={{ color: "#9FC6E6" }}>{currentUser.email}</Text>
                <Text>
                  . Por favor haga click en el enlace adjunto y refresque esta
                  página.
                </Text>
              </>
            )}
          </Card>
        </motion.div>
      </div>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 20, opacity: 1 }}
        transition={{ delay: 1.2, type: "spring", stiffness: 120 }}
      >
        <Row align="center" style={{ paddingTop: 20 }}>
          {emailSent ? (
            <Col span={24} align="center">
              <MailOutlined
                style={{ color: "#9FC6E6", fontSize: 100, padding: 50 }}
              />
            </Col>
          ) : (
            <Col span={24} align="center" style={{ padding: 50 }}>
              <Button size="large" type="primary" onClick={handleSendMail}>
                Verificar
              </Button>
            </Col>
          )}
        </Row>
      </motion.div>
      <motion.div
        align="center"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 20, opacity: 1 }}
        transition={{ delay: 1.3, type: "spring", stiffness: 120 }}
      >
        <div align="center">
          <Copyright />
        </div>
      </motion.div>
    </motion.div>
  );
}
