import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import "./print.css";
import moment from "moment";
// import QRCode from "react-qr-code";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import { PrinterOutlined } from "@ant-design/icons";
import { Row, Col, Button, Typography, Space } from "antd";

import logo from "../images/localDelivery.png";
const { Title, Text } = Typography;

const fontSize = 10;

class ComponentToPrint extends React.PureComponent {
  render() {
    let print = JSON.parse(localStorage.getItem("_print"));

    return (
      <div style={{ padding: 12 }}>
        <Row>
          <Col span={12}>
            <Text style={{ fontSize: fontSize }} className="print">
              {moment().format("lll")}
            </Text>
          </Col>
          <Col span={12}>
            <div
              style={{ position: "absolute", marginLeft: -50, paddingTop: 10 }}
            >
              <img src={logo} width={110} alt="logo" />
            </div>
            <div
              style={{ position: "absolute", marginLeft: 100, paddingTop: 10 }}
            >
              <QRCode
                value={`https://wa.me/506${print.consigneeMobile}`}
                size={75}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Title className="print" level={5}>
              <Text className="print"> Consignatario</Text>
            </Title>
            <Space direction="vertical" size={1}>
              <Text className="print" style={{ fontSize: fontSize }}>
                Nombre: {`${print.consigneeName} ${print.consigneeLastName}`}
              </Text>

              <Text className="print" style={{ fontSize: fontSize }}>
                Celular: {print.consigneeMobile}
              </Text>
              {print.consigneePhone ? (
                <Text className="print" style={{ fontSize: fontSize }}>
                  Teléfono fijo: {print.consigneeMobile}
                </Text>
              ) : null}

              {print.destination === "Domicilio" ? (
                <>
                  <Text className="print" style={{ fontSize: fontSize }}>
                    {print.coverage.join(" / ")}
                  </Text>
                  <Text className="print" style={{ fontSize: fontSize }}>
                    Dirección exacta: {print.address}
                  </Text>
                </>
              ) : (
                <Text className="print" style={{ fontSize: fontSize }}>
                  Destino: {print.destination}
                </Text>
              )}
            </Space>
          </Col>
        </Row>

        <div align="center">
          <Barcode width={1.1} height={40} value={print._id.toString()} />
        </div>
      </div>
    );
  }
}

export default function Print({ block }) {
  const componentRef = useRef();

  return (
    <div>
      <ReactToPrint
        trigger={(e) => (
          <Button
            icon={<PrinterOutlined />}
            onClick={(e) => e.preventDefault()}
            htmlType="submit"
            type="primary"
            block={block}
          >
            Imprimir
          </Button>
        )}
        content={() => componentRef.current}
      />
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} />
      </div>
    </div>
  );
}
