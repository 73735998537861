import axios from "axios";
import firebase from "firebase";

export const GetMethod = (url) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .get(url, { headers: { Authorization: `${idToken}` } })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

export const DeleteMethod = (url) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .delete(url, { headers: { Authorization: `${idToken}` } })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

export const PatchMethod = (url, data) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .patch(url, data, { headers: { Authorization: `${idToken}` } })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

export const PostMethod = (url, data) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .post(url, data, { headers: { Authorization: `${idToken}` } })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
