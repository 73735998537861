import React from "react";
import { useGlobal } from "../context/GlobalContext";
import { Button, Typography, Card, Divider } from "antd";
import { Copyright } from "../misc/Generic";
import localDelivery from "../images/localDelivery.png";
import { motion } from "framer-motion";

const { Title, Text } = Typography;
export default function WaitingAcess() {
  const { logout, currentUser } = useGlobal();

  return (
    <div>
      <div align="center" style={{ paddingTop: 40 }}>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 20, opacity: 1 }}
          transition={{ delay: 0, type: "spring", stiffness: 120 }}
        >
          <img
            src={localDelivery}
            alt="logo"
            style={{ paddingBlock: "2vh" }}
            width={250}
          />
          <Divider orientation="center" />
        </motion.div>
      </div>
      <div align="center" style={{ paddingTop: 40 }}>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 20, opacity: 1 }}
          transition={{ delay: 0.4, type: "spring", stiffness: 120 }}
        >
          <Card style={{ width: "80%" }} align="center">
            <Title level={3}>
              <Text>¡Hola </Text>
              <Text
                style={{ color: "#9FC6E6" }}
              >{`${currentUser.displayName}`}</Text>
              , te damos la bienvenida!
            </Title>
            <br />

            <>
              <Text>
                Por favor, espera que un administrador asigne los permisos.
              </Text>
              <br />
              <br />
              <br />
            </>
            <Button size="large" type="primary" onClick={() => logout()}>
              Salir
            </Button>
          </Card>
        </motion.div>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 20, opacity: 1 }}
          transition={{ delay: 0.6, type: "spring", stiffness: 120 }}
        >
          <div align="center" style={{ paddingTop: 40 }}>
            <Copyright />
          </div>
        </motion.div>
      </div>
    </div>
  );
}
