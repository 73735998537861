import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Empty,
  Form,
  Input,
  message,
  Badge,
  Tooltip,
  Select,
  Space,
  Row,
  Col,
  Collapse,
  Divider,
  Tag,
} from "antd";
import {
  CloseCircleOutlined,
  ClearOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GetMethod, PatchMethod } from "../misc/CRUD";
import { CustomHeader, Motion } from "../misc/Generic";
import { useGlobal } from "../context/GlobalContext";
import Status from "../misc/Status";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
const { Option } = Select;
const { Item } = Form;
const { Panel } = Collapse;

export default function Scan() {
  const { API, currentUser } = useGlobal();
  const [dataTable, setDatable] = useState([]);
  const [send, setSend] = useState();
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("LISTO EN COUNTER");
  const [orderNotFound, setOrderNotFound] = useState();
  const [userProfile, setUserProfile] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    let isMounted = true;

    GetMethod(`${API}users/${currentUser.email}`)
      .then((userProfile) => {
        if (isMounted) {
          setUserProfile(userProfile);
        }
      })
      .catch((_) => {});
    return () => {
      isMounted = false;
    };
  }, [API, currentUser.email]);

  const columns = [
    {
      width: 50,
      dataIndex: "_id",
      fixed: "left",
      render: (_, item) => (
        <Space direction="horizontal" size={20}>
          <Tooltip placement="left" title="Eliminar">
            <Button
              type="text"
              shape="circle"
              icon={
                <CloseCircleOutlined
                  style={{ fontSize: 20, color: "#9FC6E6" }}
                />
              }
              onClick={() => {
                let array = [];
                dataTable.forEach((element) => {
                  if (element.key !== item.key) {
                    array.push(element);
                  }
                });

                setTotal(total - 1);
                setDatable(array);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Orden",
      dataIndex: "id",
    },
    {
      title: "Estado",
      dataIndex: "status",
    },
  ];

  const onFinish = (values) => {
    setDatable((prevTabla) => [
      ...prevTabla,
      {
        id: values.id,
        status: status,
        key: uuidv4(),
      },
    ]);
    setTotal(total + 1);

    form.resetFields();
  };

  const handleSend = () => {
    let errors = [];
    if (dataTable.length > 0) {
      setSend(true);
      dataTable.forEach((e) => {
        GetMethod(`${API}orders/?id=${e.id}`)
          .then((res) => {
            if (res[0].company === userProfile.company) {
              e = {
                ...e,
                events: [
                  {
                    comment: `Cambió de ${res[0].status} a ${e.status}. Módulo Scan`,
                    createdBy: currentUser.displayName,
                    createdAt: Date.now(),
                    email: currentUser.email,
                    userPhoto: currentUser.photoURL,
                  },
                ],
              };
              PatchMethod(`${API}orders/${e.id}`, e)
                .then(() => {
                  setSend(false);
                  message.success("Se cambió el estado correctamente! 🎉");
                })
                .catch((_) => {});
            } else {
              message.error("Orden inválida");
              setSend(false);

              errors.push({ id: e.id, reason: "Pertenece a otra compañía" });
              setOrderNotFound(errors);
            }
          })
          .catch((_) => {
            message.error("Orden no existe");
            setSend(false);

            errors.push({ id: e.id, reason: "Orden no existe" });
            setOrderNotFound(errors);
          });
      });
    }
  };
  return (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      <CustomHeader
        label={
          <>
            Scan <Badge count={total} offset={[-19, -25]} />
          </>
        }
      />

      <Motion>
        <div align="center">
          <Space wrap size="large">
            <Tooltip title="Limpiar">
              <Button
                size="large"
                type="dashed"
                icon={
                  <ClearOutlined style={{ fontSize: 30, color: "#65b7f3" }} />
                }
                onClick={() => {
                  setTotal(0);
                  setDatable([]);
                  setOrderNotFound();
                }}
              />
            </Tooltip>

            <Tooltip title="Guardar">
              <Button
                type="dashed"
                loading={send}
                onClick={handleSend}
                size="large"
                icon={
                  <SaveOutlined style={{ fontSize: 30, color: "#8fd460" }} />
                }
              />
            </Tooltip>
          </Space>
        </div>

        <br />
        <br />
        <Row gutter={[24, 3]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Form onFinish={onFinish} form={form}>
              <Item
                name="id"
                hasFeedback
                rules={[
                  { min: 24, message: "Mínimo es de 24" },
                  { max: 24, message: "Máximo es de 24" },
                  { required: true, message: "Campo requerido" },
                ]}
              >
                <Input autoFocus placeholder="Orden" />
              </Item>
            </Form>
          </Col>

          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <Select
              showSearch
              placeholder="Estado"
              onChange={(e) => setStatus(e)}
              value={status}
              style={{ width: "100%" }}
            >
              {Status.map((e, i) =>
                e.name !== "ENTREGADO" ? (
                  <Option key={i} value={e.name}>
                    {e.name}
                  </Option>
                ) : null
              )}
            </Select>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            {orderNotFound ? (
              <Collapse accordion>
                <Panel
                  header={`Errores encontrados: ${orderNotFound.length}`}
                  key="1"
                >
                  {orderNotFound.map((item, i) => (
                    <div key={i}>
                      <Divider />
                      <p>
                        {`${item.id}`} <Tag color="volcano">{item.reason}</Tag>
                      </p>
                    </div>
                  ))}
                </Panel>
              </Collapse>
            ) : null}
          </Col>
        </Row>
        <br />

        <Table
          locale={{
            triggerDesc: "Ordenar descendente",
            triggerAsc: "Ordenar ascendente",
            cancelSort: "Cancelar orden",
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Sin datos"
              />
            ),
          }}
          size="small"
          columns={columns}
          loading={send}
          dataSource={dataTable}
          scroll={{ x: 1000 }}
          sticky
        />
      </Motion>
    </motion.div>
  );
}
