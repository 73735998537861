import React, { useEffect, useState } from "react";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PatchMethod, PostMethod } from "../misc/CRUD";
import { Spin } from "../misc/Generic";
import { CustomHeader, Motion } from "../misc/Generic";
import { CustomTable } from "../misc/Generic";
import { EditOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import {
  Select,
  Tag,
  Modal,
  Space,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  Tooltip,
} from "antd";

export default function Companies() {
  const { API } = useGlobal();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [error, setError] = useState("");
  const [visible, setVisible] = useState(false);
  const [editData, seteditData] = useState();
  const [form] = Form.useForm();
  //#region   //? =---> MISC

  useEffect(() => {
    let isMounted = true;

    GetMethod(`${API}companies/`)
      .then((res) => {
        if (isMounted) setData(res);
        setLoading(true);
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
      });

    return () => {
      isMounted = false;
    };
  }, [API]);

  const handleCreate = () => {
    seteditData("");
    form.resetFields();
    form.setFieldsValue({ cambiarContrasena: true });
    setVisible(true);
  };
  //#endregion

  //#region   //? =---> UPDATE || POST in onFinish())
  const handleEdit = (rowData) => {
    form.setFieldsValue({ cambiarContrasena: false });
    setVisible(true);
    seteditData(rowData);
  };

  const CustomizedForm = ({ onChange, fields }) => {
    const onFinish = async (values) => {
      setVisible(false);
      console.log(values);
      //#region //? =---> UPDATE
      if (editData) {
        setSend(true);

        PatchMethod(`${API}companies/${editData._id}`, values)
          .then((res) => {
            setSend(false);
            setData(res);
            message.success("Actualizado 🎉");
          })
          .catch((err) => {
            setError(err);
            setSend(false);
          });
        //#endregion

        //#region //? =---> POST
      } else {
        setSend(true);

        PostMethod(`${API}companies/`, values)
          .then((res) => {
            setSend(false);
            message.success("Agregado! 🎉");
            setData(res);
          })
          .catch((err) => {
            setError(err);
            setSend(false);
          });
      }

      //#endregion
    };

    return (
      <Form form={form} onFinish={onFinish} layout="vertical" fields={fields}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="company"
                  label="Compañía"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="contactName"
                  label="Representante"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="contactEmail"
                  label="Correo"
                  rules={[
                    { required: true, message: "Campo requerido" },
                    { type: "email", message: "Correo inválido" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="contactPhone"
                  label="Teléfono"
                  rules={[
                    { required: true, message: "Campo requerido" },
                    {
                      pattern: "^[0-9]+$",
                      message: "Sólo se permiten números sin espacios",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Estado"
                  rules={[{ required: true, message: "Campo requerido" }]}
                >
                  <Select>
                    <Select.Option value="active">Activo</Select.Option>
                    <Select.Option value="inactive">Inactivo</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="corporateIdentification"
                  label="Cédula"
                  rules={[
                    { required: true, message: "Campo requerido" },
                    {
                      pattern: "^[0-9]+$",
                      message: "Sólo se permiten números sin espacios",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <div
          style={{
            textAlign: "center",
            paddingTop: 50,
          }}
        >
          <Button
            type="text"
            onClick={() => setVisible(false)}
            style={{ marginRight: 8, borderRadius: 40 }}
          >
            Cancelar
          </Button>
          <Button style={{ borderRadius: 40 }} type="primary" htmlType="submit">
            Enviar
          </Button>
        </div>
      </Form>
    );
  };

  const EditForm = () => {
    const [fields, setFields] = useState([
      {
        name: "company",
        value: editData ? editData.company : null,
      },
      {
        name: "status",
        value: editData ? editData.status : null,
      },
      {
        name: "contactName",
        value: editData ? editData.contactName : null,
      },
      {
        name: "contactEmail",
        value: editData ? editData.contactEmail : null,
      },
      {
        name: "contactPhone",
        value: editData ? editData.contactPhone : null,
      },
      {
        name: "corporateIdentification",
        value: editData ? editData.corporateIdentification : null,
      },
    ]);

    return (
      <CustomizedForm
        fields={fields}
        onChange={(newFields) => {
          setFields(newFields);
        }}
      />
    );
  };

  //#endregion

  //#region  //? =---> Table props

  const columns = [
    {
      width: 50,
      dataIndex: "_id",
      fixed: "left",
      render: (record, index) => (
        <Space direction="horizontal" size={20}>
          <Tooltip placement="left" title="Editar">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEdit(index)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Compañía",
      dataIndex: "company",
      sorter: (a, b) => a.company.length - b.company.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Cédula",
      dataIndex: "corporateIdentification",
      sorter: (a, b) =>
        a.corporateIdentification.length - b.corporateIdentification.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Representante",
      dataIndex: "contactName",
      sorter: (a, b) => a.contactName.length - b.contactName.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Correo",
      dataIndex: "contactEmail",
      sorter: (a, b) => a.contactEmail.length - b.contactEmail.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Teléfono",
      dataIndex: "contactPhone",
      sorter: (a, b) => a.contactPhone.length - b.contactPhone.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      render: (item) => (
        <Row>
          <Col span={24}>
            {item.status === "active" ? (
              <Tag color="lime">Activo</Tag>
            ) : item.status === "inactive" ? (
              <Tag color="volcano">Inactivo</Tag>
            ) : null}
          </Col>
        </Row>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
    },
  ];
  //#endregion

  return (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      <CustomHeader label="Compañías" />

      {error ? (
        <span>{error.message} </span>
      ) : (
        <>
          {!loading ? (
            <Spin />
          ) : (
            <Motion>
              <CustomTable
                handleCreate={handleCreate}
                columns={columns}
                send={send}
                data={data}
                showButton
              />

              <Modal
                width={500}
                title={editData ? "Editar" : "Crear compañía"}
                onCancel={() => setVisible(false)}
                visible={visible}
                footer={null}
              >
                <div align="center">
                  <EditForm />
                </div>
              </Modal>
            </Motion>
          )}
        </>
      )}
    </motion.div>
  );
}
