import React, { useEffect, useState } from "react";
import { CustomHeader, Spin, Motion } from "../misc/Generic";
import Status from "../misc/Status";
import { GetMethod } from "../misc/CRUD";
import { useHistory } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import { motion } from "framer-motion";
import CsvDownloader from "react-csv-downloader";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Weight from "../images/weight.svg"
import OpenOrder from "../images/openOrder.svg"
import Copy from "../images/copy.svg"
import moment from "moment";
import {
  EyeOutlined,
  ClearOutlined,
  SyncOutlined,
  DownloadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  Space,
  Form,
  Button,
  Empty,
  Divider,
  Input,
  Tooltip,
  Typography,
  Table,
  Select,
  Row,
  Col,
  DatePicker,
  message,
  Collapse,
} from "antd";

const { Panel } = Collapse;
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { Item } = Form;
const { RangePicker } = DatePicker;

export default function History() {
  const { API, currentUser } = useGlobal();
  const [form] = Form.useForm();
  const history = useHistory();
  const [allOrders, setAllOrders] = useState();
  const [tempOrders, setTempOrders] = useState();
  const [userData, setUserData] = useState();
  const [branches, setBranches] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //#region   //? =---> MISC

  const getData = () => {
    setIsLoading(true);
    form.resetFields();

    GetMethod(`${API}users/${currentUser.email}`)
      .then((userProfile) => {
        setUserData(userProfile);
        if (
          userProfile.role === "mensajero" ||
          userProfile.role === "admin" ||
          userProfile.role === "servicioCliente" ||
          userProfile.role === "reportes" ||
          userProfile.role === "sucursal"
        ) {
          GetMethod(`${API}allOrders`).then((res) => {
            setAllOrders(res);
            setTempOrders(res);
            setIsLoading(false);
          });
        } else {
          GetMethod(`${API}orders/${userProfile.company}`).then((res) => {
            setAllOrders(res);
            setTempOrders(res);
            setIsLoading(false);
          });
        }
      })
      .then(() => {
        GetMethod(`${API}branches`)
          .then((res) => {
            setBranches(res);
          })
          .catch((e) => {

          });
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region //? =---> Filters
  const resetFilters = () => {
    form.resetFields();
    setAllOrders(tempOrders);
  };

  const searchStatus = (searchText) => {
    if (searchText.length === 0) {
      resetFilters();
    } else {
      let filteredData = [];
      allOrders.filter((item) => {
        return searchText.forEach((element) => {
          if (element === item.status) {
            filteredData.push(item);
          }
        });
      });

      setAllOrders(filteredData);
    }
  };

  const searchOrigin = (searchText) => {
    if (searchText.length === 0) {
      resetFilters();
    } else {
      let filteredData = [];
      allOrders.filter((item) => {
        return searchText.forEach((element) => {
          if (element === item.originStore) {
            filteredData.push(item);
          }
        });
      });
      setAllOrders(filteredData);
    }
  };

  const searchDestination = (searchText) => {
    if (searchText.length === 0) {
      resetFilters();
    } else {
      let filteredData = [];
      allOrders.filter((item) => {
        return searchText.forEach((element) => {
          if (element === item.destination) {
            filteredData.push(item);
          }
        });
      });
      setAllOrders(filteredData);
    }
  };

  const searchConsigneeName = (searchText) => {

    if (!searchText) {
      resetFilters();
    } else {
      var filteredData = allOrders.filter((item) => {
        return item.consigneeName === searchText;
      });
      setAllOrders(filteredData);
    }
  };

  const searchAccount = (searchText) => {
    var filteredData = allOrders.filter((item) => {
      return item.accountNumber === searchText;
    });
    setAllOrders(filteredData);
  };

  const searchDate = (dates, dateStrings) => {
    let data = [];
    try {
      if (dateStrings[0].length === 0) {
        resetFilters();
      } else {
        if (dateStrings[0] === dateStrings[1]) {
          allOrders.forEach((e) => {
            if (
              moment(moment(e.date).format("YYYY-MM-DD")).isSame(dateStrings[0])
            ) {
              data.push(e);
            }
          });
          setAllOrders(data);
        } else {
          // range of days
          allOrders.forEach((e) => {
            if (
              moment(moment(e.date).format("YYYY-MM-DD")).isBetween(
                dateStrings[0],
                dateStrings[1]
              )
            ) {
              data.push(e);
            }
          });

          setAllOrders(data);
        }
      }
    } catch (error) {
      resetFilters();
    }
  };
  //#endregion

  //#endregion

  //#region  //? =---> Table props
  const columns = [
    {
      width: 50,
      dataIndex: "_id",
      fixed: "left",
      render: (_, item) => (
    
          <Tooltip placement="left" title="Detalles">
            <div align="center">

            <img width={20} src={OpenOrder} alt={item._id}  onClick={() =>
                history.push({
                  pathname: "/detailed-order",
                  search: `?id=${item._id}`,
                  state: userData,
                })
              }/>
              </div>
           
          </Tooltip>

      ),
    },
    {
      title: <Title level={4}> Paquete</Title>,
      children: [
        {
          title: "Orden",
          render: (_,item) => (
              <div align="center">
            <Space>

              <Tooltip placement="top" title="Copiar">
                <CopyToClipboard
                  text={item._id}
                  onCopy={() => message.success("Número de orden copiada", 4)}
                  >
                   <img src={Copy} alt={item._id} width={20} />
                </CopyToClipboard>
              </Tooltip>
               
              <Tooltip placement="top" title={`${item.weight} kg`}>
               <img src={Weight} alt={item._id} width={25} />
              </Tooltip>
              
              <Tooltip
                placement="top"
                title={
                  <Typography.Title level={5}>{item._id}</Typography.Title>
                }
                >
                {item._id.slice(-4)}...
              </Tooltip>
            </Space>
                </div>
          ),
        },
        {
          title: "Cuenta",
          dataIndex: "accountNumber",
          sorter: (a, b) => Number(a.accountNumber) - Number(b.accountNumber),
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Estado",
          dataIndex: "status",
          sorter: (a, b) => a.status.length - b.status.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Origen",
          dataIndex: "originStore",
          sorter: (a, b) => a.originStore.length - b.originStore.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Destino",
          dataIndex: "destination",
          sorter: (a, b) => a.destination.length - b.destination.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          width: 150,
          title: "Creación",
          sorter: (a, b) => moment(a.date) - moment(b.date),
          render: (item, i) => (
            <>
              <Tooltip placement="top" title={moment(item.date).format("LLL")}>
                {moment(item.date).format("LL")}
              </Tooltip>
            </>
          ),
        },
        {
          title: "Creador",

          render: (item) => (
            <Tooltip placement="top" title={item.creatorEmail}>
              {item.creatorName}
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: <Title level={4}> Consignatario</Title>,
      children: [
        {
          title: "Nombre",
          render: (index, record) => (
            <span>{`${record.consigneeName} ${record.consigneeLastName} `}</span>
          ),
          sorter: (a, b) =>
            a.consigneeName.length +
            a.consigneeLastName.length -
            b.consigneeName.length +
            b.consigneeLastName.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Cédula",
          dataIndex: "consigneeId",
        },
        {
          title: "Celular",
          dataIndex: "consigneeMobile",
          sorter: (a, b) =>
            Number(a.consigneeMobile) - Number(b.consigneeMobile),
          sortDirections: ["descend", "ascend"],
        },
      ],
    },
  ];
  //#endregion

  const headers = [
    {
      id: "creatorName",
      displayName: "creatorName",
    },
    {
      id: "creatorEmail",
      displayName: "creatorEmail",
    },
    {
      id: "status",
      displayName: "status",
    },
    {
      id: "accountNumber",
      displayName: "accountNumber",
    },
    {
      id: "senderLastName",
      displayName: "senderLastName",
    },
    {
      id: "senderMobile",
      displayName: "senderMobile",
    },
    {
      id: "senderEmail",
      displayName: "senderEmail",
    },
    {
      id: "originStore",
      displayName: "originStore",
    },
    {
      id: "consigneeId",
      displayName: "consigneeId",
    },
    {
      id: "consigneeName",
      displayName: "consigneeName",
    },
    {
      id: "consigneeLastName",
      displayName: "consigneeLastName",
    },
    {
      id: "consigneeMobile",
      displayName: "consigneeMobile",
    },
    {
      id: "consigneeEmail",
      displayName: "consigneeEmail",
    },
    {
      id: "destination",
      displayName: "destination",
    },
    {
      id: "weight",
      displayName: "weight",
    },
    {
      id: "cost",
      displayName: "cost",
    },
    {
      id: "description",
      displayName: "description",
    },
    {
      id: "date",
      displayName: "date",
    },
  ];

  return (
    <motion.div
      exit={{ y: 100, opacity: 0, transition: { ease: "easeInOut" } }}
    >
      <CustomHeader label="Histórico" />
      {!allOrders || !userData || !branches ? (
        <Spin />
      ) : (
        <div align="center">
          <Motion>
            <Collapse bordered={false}>
              <Panel
                header={
                  <Space size={20}>
                    <Tooltip title="Limpiar Filtros">
                      <Button
                        size="large"
                        type="text"
                        icon={
                          <ClearOutlined
                            style={{ fontSize: 30, color: "#65b7f3" }}
                          />
                        }
                        onClick={(e) => {
                          resetFilters();
                          e.stopPropagation();
                        }}
                        htmlType="submit"
                      />
                    </Tooltip>
                    <Tooltip title="Refrescar">
                      <Button
                        onClick={(e) => {
                          getData();
                          e.stopPropagation();
                        }}
                        size="large"
                        type="text"
                        icon={
                          <SyncOutlined
                            style={{ fontSize: 30, color: "#f3ea62" }}
                          />
                        }
                        htmlType="submit"
                      />
                    </Tooltip>
                    <Tooltip title="Descargar">
                      <CsvDownloader
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          background: "transparent",
                          borderColor: "transparent",
                        }}
                        filename={`Local_Delivery_${moment().format(
                          "DD/MM/YYYY-h:mm:ss_a"
                        )}`}
                        separator=";"
                        text={
                          <DownloadOutlined
                            disabled={allOrders ? false : true}
                            style={{
                              fontSize: 30,
                              color: "#8fd460",
                              marginTop: 6,
                            }}
                          />
                        }
                        columns={headers}
                        datas={allOrders}
                      />
                    </Tooltip>
                  </Space>
                }
                key="1"
              >
                <Form form={form} layout="vertical" onFinish={resetFilters}>
                  <Row gutter={[24, 2]} style={{ padding: 10 }}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Item name="accountNumber">
                        <Search
                          placeholder="Cuenta"
                          onSearch={searchAccount}
                          style={{ width: "100%" }}
                        />
                      </Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Item name="status">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Estado"
                          onChange={searchStatus}
                        >
                          {Status.map((item, i) => (
                            <Option key={i} value={item.name}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Item name="origin">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Origen"
                          onChange={searchOrigin}
                        >
                          {branches.map((e, i) =>
                            e.name !== "Domicilio" ? (
                              <Option key={i} value={e.name}>
                                {e.name}
                              </Option>
                            ) : null
                          )}
                        </Select>
                      </Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Item name="destination">
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Destino"
                          onChange={searchDestination}
                        >
                          {branches.map((e, i) => (
                            <Option key={i} value={e.name}>
                              {e.name}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Item name="consigneeName">
                        <Select
                          allowClear
                          showSearch
                          loading={allOrders ? false : true}
                          style={{ width: "100%" }}
                          placeholder="Nombre Consignatario"
                          onChange={searchConsigneeName}
                        >
                          {allOrders.map((e, i) => (
                            <Option key={i} value={e.consigneeName}>
                              {e.consigneeName}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                      <Item name="date">
                        <RangePicker
                          style={{ width: "100%" }}
                          disabledDate={(currentDate) =>
                            currentDate.isAfter(moment())
                          }
                          ranges={{
                            Hoy: [moment(), moment()],
                            "Este mes": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                          }}
                          onChange={searchDate}
                        />
                      </Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
            <Divider />
            <Table
              footer={() => <span>Total de elementos: {allOrders.length}</span>}
              bordered
              loading={isLoading}
              locale={{
                triggerDesc: "Ordenar descendente",
                triggerAsc: "Ordenar ascendente",
                cancelSort: "Cancelar orden",
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Sin datos"
                  />
                ),
              }}
              size="small"
              columns={columns}
              dataSource={allOrders}
              scroll={{ x: 1000 }}
              sticky
            />
          </Motion>
        </div>
      )}
    </motion.div>
  );
}
