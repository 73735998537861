import React from "react";
import Image from "../images/404.svg";
import { motion } from "framer-motion";
import { GooSpinner } from "react-spinners-kit";
import emailjs from "emailjs-com";
import {
  Progress,
  Table,
  Button,
  Empty,
  PageHeader,
  Typography,
  Space,
  Tooltip,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const { Text, Link } = Typography;

export const Permissions = (menu) => {
  let _orders = ["admin", "sucursal", "externo"];
  let _history = [
    "admin",
    "sucursal",
    "externo",
    "mensajero",
    "servicioCliente",
  ];
  let _scan = ["admin", "sucursal", "externo"];
  let _profile = ["admin", "sucursal", "externo"];
  let _reports = ["admin", "reportes"];
  let _users = ["admin"];
  let _companies = ["admin"];

  switch (menu) {
    case "orders":
      return _orders;

    case "history":
      return _history;

    case "scan":
      return _scan;

    case "profile":
      return _profile;

    case "reports":
      return _reports;

    case "users":
      return _users;

    case "companies":
      return _companies;

    default:
      break;
  }
};

export const Denied = () => (
  <Motion>
    <img style={{ width: "100%", height: "70vh" }} src={Image} alt="notfound" />
  </Motion>
);

export const Spin = () => (
  <div align="center">
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 20, opacity: 1 }}
      transition={{ delay: 0.4, type: "spring", stiffness: 120 }}
    >
      <GooSpinner color="#9fc6e6" size={75} />
    </motion.div>
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 20, opacity: 1 }}
      transition={{ delay: 2.4, type: "spring", stiffness: 120 }}
    >
      <span>Por favor espere...</span>
    </motion.div>
  </div>
);

export const CustomTable = (props) => (
  <Table
    locale={{
      triggerDesc: "Ordenar descendente",
      triggerAsc: "Ordenar ascendente",
      cancelSort: "Cancelar orden",
      emptyText: (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sin datos" />
      ),
    }}
    title={() =>
      props.showButton ? (
        <div align="center" style={{ paddingBottom: 10 }}>
          <Space size="large">
            <Tooltip title="Crear usuario">
              <Button
                onClick={props.handleCreate}
                icon={
                  <PlusOutlined style={{ fontSize: 30, color: "#8fd460" }} />
                }
                type="dashed"
                size="large"
              />
            </Tooltip>

            <Tooltip title="Refrescar">
              <Button
                onClick={props.handleRefresh}
                size="large"
                type="dashed"
                icon={
                  <SyncOutlined style={{ fontSize: 30, color: "#65b7f3" }} />
                }
                htmlType="submit"
              />
            </Tooltip>
          </Space>
        </div>
      ) : null
    }
    size="small"
    footer={() => `Total de elementos: ${props.data.length}`}
    columns={props.columns}
    loading={props.send}
    dataSource={props.data}
    scroll={{ x: 1000 }}
    sticky
  />
);

export const CustomHeader = (props) => (
  <motion.div
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 20, opacity: 1 }}
    transition={{ delay: 0, type: "spring", stiffness: 120 }}
  >
    <PageHeader
      style={{ paddingBottom: 10 }}
      backIcon={false}
      title={<Typography.Title level={1}>{props.label}</Typography.Title>}
      subTitle={props.subTitle}
      onBack={() => window.history.back()}
    />
  </motion.div>
);

export const SendEmail = async (template, data) => {
  await emailjs.send(
    "service_iqtrsrq",
    template,
    data,
    "user_zFjUsHC8CDA4PysDgC6wj"
  );
};

export const Copyright = () => (
  <>
    <Text style={{ paddingBottom: 0 }} type="secondary">
      {"© "} {new Date().getFullYear()}{" "}
    </Text>
    <Link href="https://aeropost.com/" target="https://aeropost.com/">
      Aeropost Internacional Services, INC.
    </Link>
  </>
);

export const Motion = ({ children }) => {
  const MotionDiv = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      y: 20,
      opacity: 1,
      transition: {
        delay: 0,
        type: "spring",
        stiffness: 120,
      },
    },
  };
  return (
    <motion.div variants={MotionDiv} initial={"hidden"} animate={"visible"}>
      {children}
    </motion.div>
  );
};

export const ProgressBar = (delay) => (
  <motion.div
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 14, opacity: 1 }}
    transition={{ delay: delay ? delay : 1, type: "spring", stiffness: 100 }}
  >
    <Progress
      strokeWidth={"1.5vh"}
      status="active"
      style={{ width: "30%" }}
      showInfo={false}
      strokeColor="#9FC6E6"
      percent={100}
    />
  </motion.div>
);

//#region

export function SiteTable({ send, data }) {
  const columns = [
    {
      width: "12%",
      dataIndex: "_id",
      fixed: "left",
      render: (record, index) => (
        <Space direction="horizontal" size={20}>
          <Tooltip placement="left" title="Editar">
            <Button
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size={"small"}
              // onClick={() => handleEdit(index)}
            />
          </Tooltip>
          <Tooltip placement="right" title="Eliminar">
            <Button
              type="dashed"
              shape="round"
              danger
              icon={<DeleteOutlined />}
              size={"small"}
              // onClick={() => showConfirm(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Item",
      dataIndex: "name",
    },
  ];

  //#region   //? =---> DELETE
  // function showConfirm(record) {
  //   confirm({
  //     title: "¿Está seguro que desea eliminarlo?",
  //     icon: <ExclamationCircleOutlined />,
  //     async onOk() {
  //       setSend(true);

  //       await DeleteMethod(`${API}paises/${record}`)
  //         .then((res) => {
  //           setSend(false);
  //           setData(res);
  //           message.success(`Eliminado 🎉`);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     },
  //   });
  // }
  //#endregion

  return (
    <CustomTable
      // handleCreate={props.handleCreate}
      columns={columns}
      send={send}
      data={data}
      showButton
    />
  );
}

//#endregion
