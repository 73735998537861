import React, { useState, useEffect } from "react";
import { GetMethod } from "../misc/CRUD";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useGlobal } from "../context/GlobalContext";
import { Permissions } from "../misc/Generic";
import Login from "../login/MainPage";
import PrivateRoute from "../routes/PrivateRoutes";
import Users from "../views/Users";
import Profile from "../views/Profile";
import Companies from "../views/Companies";
import DetailledOrder from "../views/DetailledOrder";
import History from "../views/History";
import Reports from "../views/Reports";
import Scan from "../views/Scan";
import Orders from "../views/Orders";

export default function Body() {
  const [role, setRole] = useState();
  const { currentUser, API } = useGlobal();
  const location = useLocation();

  useEffect(() => {
    let mounted = false;

    GetMethod(`${API}users/${currentUser.email}`).then((res) => {
      if (!mounted) {
        setRole(res.role);
      }
    });
    return () => {
      mounted = true;
    };
  }, [API, currentUser.email]);

  return role ? (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        <PrivateRoute exact path="/">
          <Redirect to="/history" />
        </PrivateRoute>
        <PrivateRoute
          path="/companies"
          component={() =>
            Permissions("companies").map(
              (item, i) => item === role && <Companies key={i} />
            )
          }
        />
        <PrivateRoute
          path="/users"
          component={() =>
            Permissions("users").map(
              (item, i) => item === role && <Users key={i} />
            )
          }
        />

        <PrivateRoute
          path="/orders"
          component={() =>
            Permissions("orders").map(
              (item, i) => item === role && <Orders key={i} />
            )
          }
        />

        <PrivateRoute
          path="/history"
          component={() =>
            Permissions("history").map(
              (item, i) => item === role && <History key={i} />
            )
          }
        />

        <PrivateRoute
          path="/reports"
          component={() =>
            Permissions("reports").map(
              (item, i) => item === role && <Reports key={i} />
            )
          }
        />

        <PrivateRoute
          path="/profile"
          component={() =>
            Permissions("profile").map(
              (item, i) => item === role && <Profile key={i} />
            )
          }
        />

        <PrivateRoute
          path="/detailed-order"
          component={() => <DetailledOrder />}
        />

        <PrivateRoute
          path="/scan"
          component={() =>
            Permissions("scan").map(
              (item, i) => item === role && <Scan key={i} />
            )
          }
        />

        <Route path="/login" component={Login} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </AnimatePresence>
  ) : null;
}
