import React, { useState } from "react";
import { useGlobal } from "../context/GlobalContext";
import { message, Button, Tooltip } from "antd";
import { MailOutlined } from "@ant-design/icons";

export default function ResetPassword({ editEmail, label, block, fromAdmin }) {
  const { resetPassword, currentUser } = useGlobal();
  const [sendEmail, setSendEmail] = useState();

  const hanldeSendEmail = async () => {
    await resetPassword(editEmail ? editEmail : currentUser.email);
    setSendEmail(true);
    setSendEmail(false);
    message.success(
      `${
        fromAdmin
          ? `Se envió un correo a la dirección: ${editEmail}`
          : "Por favor revisa el correo 🚀"
      }`
    );
  };

  return (
    <Tooltip
      placement="left"
      title={
        editEmail.replace(/.*@/, "") === "aeropost.com"
          ? "Usuarios de aeropost inician sesión con Google"
          : "Cambio de contraseña"
      }
    >
      <Button
        type={fromAdmin ? "text" : "primary"}
        icon={<MailOutlined />}
        shape={fromAdmin ? "circle" : "round"}
        block={block}
        loading={sendEmail}
        onClick={hanldeSendEmail}
        disabled={
          editEmail.replace(/.*@/, "") === "aeropost.com" ? true : false
        }
      >
        {!fromAdmin ? label : null}
      </Button>
    </Tooltip>
  );
}
